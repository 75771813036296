import { InputNumber } from 'antd'
import { PartNoBg } from '../styledComponents'
import { UilCheck } from '@iconscout/react-unicons'

const createColumnsCM = (getColumnSearchProps) => {
  const performeableservices = {
    variant: { a: 1, b: 2, c: 3, d: 4 },
  }

  const columns = [
    {
      title: 'Client Material',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Anzahl',
      dataIndex: 'count',
      key: 'count',
    },
    {
      title: 'DWH Material',
      //...getColumnSearchProps(['attributes', '4', 'value']),
      //render: (record) => record.attributes.filter(x => x.key == "DWHMaterial").map(row => row.value),
      dataIndex: 'dwhmaterial',
      key: 'dwhmaterial',
    },
  ]

  return columns
}

export default createColumnsCM
