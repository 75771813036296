import { Button } from 'antd'

const PrivacyDE = () => {
  const openPrivacySettings = () => {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer()
    } else {
      console.error('UserCentrics UI is not available.')
    }
  }
  return (
    <div style={{ margin: '0 200px' }}>
      <h1>Datenschutzhinweise</h1>
      <p>
        <strong>
          Verantwortlicher im Sinne der EU-Datenschutz-Grundverordnung (DSGVO)
          ist:
        </strong>
      </p>
      <p>
        Daimler Buses GmbH („Wir“)
        <br />
        Fasanenweg 10
        <br />
        70771 Leinfelden-Echterdingen
        <br />
        Deutschland
        <br />
        E-Mail:{' '}
        <a href="mailto:dataprivacy-bus@daimlertruck.com">
          dataprivacy-bus@daimlertruck.com
        </a>
      </p>

      <h2>Datenschutzbeauftragter:</h2>
      <p>
        Daimler Truck AG
        <br />
        Konzerndatenschutzbeauftragte
        <br />
        HPC DTF2B
        <br />
        70745 Leinfelden-Echterdingen
        <br />
        Deutschland
        <br />
        E-Mail:{' '}
        <a href="mailto:dataprivacy@daimlertruck.com">
          dataprivacy@daimlertruck.com
        </a>
      </p>

      <h2>1. Datenschutz</h2>
      <p>
        Wir freuen uns über Ihren Besuch auf unseren Webseiten und Ihr Interesse
        an unseren Angeboten. Der Schutz Ihrer personenbezogenen Daten ist für
        uns ein wichtiges Anliegen. In diesen Datenschutzhinweisen erklären wir,
        wie wir Ihre personenbezogenen Daten erheben, was wir damit tun, für
        welche Zwecke und auf welchen Rechtsgrundlagen dies geschieht, und
        welche Rechte und Ansprüche sich damit für Sie verbinden. Zusätzlich
        verweisen wir auf die Daimler-Truck Datenschutzrichtlinie EU:
      </p>
      <p>
        <a href="https://www.daimlertruck.com/fileadmin/user_upload/dokumente/unternehmen/datenschutz/datenschutzrichtlinie.pdf">
          Daimler Truck Datenschutzrichtlinie EU
        </a>
        .
      </p>
      <p>
        Unsere Datenschutzhinweise für den Gebrauch unserer Webseiten und die
        Datenschutzrichtlinie der Daimler Truck AG gelten nicht für Ihre
        Aktivitäten auf den Webseiten von sozialen Netzwerken oder anderen
        Anbietern, die Sie über die Links auf unseren Webseiten erreichen
        können. Bitte informieren Sie sich auf den Webseiten dieser Anbieter
        über deren Datenschutzbestimmungen.
      </p>

      <h2>2. Erhebung und Verarbeitung Ihrer personenbezogenen Daten</h2>
      <p>
        <strong>a.</strong> Wenn Sie unsere Webseiten besuchen, speichern wir
        bestimmte Angaben zu dem von Ihnen verwendeten Browser und
        Betriebssystem, das Datum und die Uhrzeit des Besuches, den
        Zugriffsstatus (z.B. ob Sie eine Webseite aufrufen konnten oder eine
        Fehlermeldung erhielten), die Nutzung von Funktionen der Webseite, die
        von Ihnen möglicherweise eingegebenen Suchbegriffe, die Häufigkeit, mit
        der Sie einzelne Webseiten aufrufen, die Bezeichnung abgerufener
        Dateien, die übertragene Datenmenge, die Webseite, von der aus Sie auf
        unsere Webseiten gelangt sind, und die Webseite, die Sie von unseren
        Webseiten aus besuchen, sei es, indem Sie Links auf unseren Webseiten
        anklicken oder eine Domain direkt in das Eingabefeld derselben
        Registerkarte (bzw. desselben Fensters) Ihres Browsers eingeben, worin
        Sie unsere Webseiten geöffnet haben. Außerdem speichern wir aus
        Sicherheitsgründen, insbesondere zur Vorbeugung vor und Erkennung von
        Angriffen auf unsere Webseiten oder Betrugsversuchen, für die Dauer von
        sieben Tagen Ihre IP-Adresse und den Namen Ihres Internet Service
        Providers.
      </p>
      <p>
        <strong>b.</strong> Andere personenbezogene Daten speichern wir nur,
        falls Sie uns diese Daten z.B. im Rahmen einer Registrierung, eines
        Kontaktformulars, eines Chats, einer Umfrage, eines Preisausschreibens
        oder zur Durchführung eines Vertrages mitteilen, und auch in diesen
        Fällen nur, soweit uns dies aufgrund einer von Ihnen erteilten
        Einwilligung oder nach den geltenden Rechtsvorschriften (siehe Ziffer 7)
        gestattet ist.
      </p>
      <p>
        <strong>c.</strong> Sie sind weder gesetzlich noch vertraglich
        verpflichtet, Ihre personenbezogenen Daten zu überlassen. Möglich ist
        aber, dass bestimmte Funktionen unserer Webseiten von der Überlassung
        personenbezogener Daten abhängen. Falls Sie in diesen Fällen
        personenbezogene Daten nicht überlassen, kann dies dazu führen, dass
        Funktionen nicht oder nur eingeschränkt zur Verfügung stehen.
      </p>

      <h2>3. Nutzungszwecke</h2>
      <p>
        <strong>a.</strong> Die bei einem Besuch unserer Webseiten erhobenen
        personenbezogenen Daten verwenden wir, um diese für Sie möglichst
        komfortabel zu betreiben sowie unsere IT-Systeme vor Angriffen und
        anderen rechtswidrigen Handlungen zu schützen.
      </p>
      <p>
        <strong>b.</strong> Soweit Sie uns weitere personenbezogene Daten z.B.
        im Rahmen einer Registrierung, eines Chats, eines Kontaktformulars,
        einer Umfrage, eines Preisausschreibens oder zur Durchführung eines
        Vertrages mitteilen, nutzen wir diese Daten zu den genannten Zwecken, zu
        Zwecken der Kundenverwaltung und – soweit erforderlich – zu Zwecken der
        Abwicklung und Abrechnung etwaiger Geschäftsvorgänge, jeweils in dem
        dafür erforderlichen Umfang.
      </p>
      <p>
        <strong>c.</strong> Für weitere Zwecke (z. B. Anzeige von
        personalisierten Inhalten oder Werbung auf der Basis Ihres
        Nutzungsverhaltens) nutzen wir und ggf. ausgewählte Dritte Ihre Daten,
        soweit Sie dazu im Rahmen unseres Consent Management Systems Ihre
        Einwilligung (= Zustimmung) geben. Weitere Informationen und
        Entscheidungsmöglichkeiten erhalten Sie hier:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Cookie-Einstellungen öffnen
        </Button>
      </p>
      <p>
        <strong>d.</strong> Außerdem nutzen wir personenbezogene Daten, soweit
        wir dazu rechtlich verpflichtet sind (z. B. Speicherung zur Erfüllung
        handels- oder steuerrechtlicher Aufbewahrungspflichten, Herausgabe gem.
        behördlicher oder gerichtlicher Anordnung, z. B. an eine
        Strafverfolgungsbehörde).
      </p>

      <h2>
        4. Übertragung personenbezogener Daten an Dritte; Social Plug-ins;
        Einsatz von Dienstleistern
      </h2>
      <p>
        <strong>a.</strong> Unsere Webseiten können auch Angebote Dritter
        enthalten. Wenn Sie ein solches Angebot anklicken, übertragen wir im
        erforderlichen Umfang Daten an den jeweiligen Anbieter (z. B. die
        Angabe, dass Sie dieses Angebot bei uns gefunden haben und ggf. weitere
        Informationen, die Sie hierfür auf unseren Webseiten bereits angegeben
        haben).
      </p>
      <p>
        <strong>b.</strong> Wenn wir auf unseren Webseiten sogenannte „Social
        Plug-ins“ sozialer Netzwerke wie Facebook und Twitter einsetzen, binden
        wir diese wie folgt ein: Wenn Sie unsere Webseiten besuchen, sind die
        Social Plug-ins deaktiviert, d.h. es findet keine Übertragung
        irgendwelcher Daten an die Betreiber dieser Netzwerke statt. Falls Sie
        eines der Netzwerke nutzen möchten, klicken Sie auf das jeweilige Social
        Plug-in, um eine direkte Verbindung mit dem Server des jeweiligen
        Netzwerks aufzubauen. Falls Sie bei dem Netzwerk ein Nutzerkonto haben
        und im Moment des Aktivierens des Social Plug-ins dort eingeloggt sind,
        kann das Netzwerk Ihren Besuch der unserer Webseiten Ihrem Nutzerkonto
        zuordnen. Wenn Sie das vermeiden möchten, loggen Sie sich bitte vor der
        Aktivierung des Social Plug-ins aus dem Netzwerk aus. Den Besuch anderer
        Daimler-Webseiten kann ein soziales Netzwerk nicht zuordnen, bevor Sie
        nicht auch ein dort vorhandenes Social Plug-in aktiviert haben. Wenn Sie
        ein Social Plug-in aktivieren, überträgt das Netzwerk die dadurch
        verfügbar werdenden Inhalte direkt an Ihren Browser, der sie in unsere
        Webseiten einbindet. In dieser Situation können auch Datenübertragungen
        stattfinden, die vom jeweiligen sozialen Netzwerk initiiert und
        gesteuert werden. Für Ihre Verbindung zu einem sozialen Netzwerk, die
        zwischen dem Netzwerk und Ihrem System stattfindenden Datenübertragungen
        und für Ihre Interaktionen auf dieser Plattform gelten ausschließlich
        die Datenschutzbestimmungen des jeweiligen Netzwerks. <br />
        Das Social Plug-in bleibt aktiv, bis Sie es deaktivieren oder Ihre
        Webseiten-Technologien löschen (siehe Ziffer 5.d).
      </p>
      <p>
        <strong>c.</strong> Wenn Sie den Link zu einem Angebot anklicken oder
        ein Social Plug-in aktivieren, kann es sein, dass personenbezogene Daten
        zu Anbietern in Ländern außerhalb des Europäischen Wirtschaftsraums
        gelangen, die aus der Sicht der Europäischen Union („EU“) kein den
        EU-Standards entsprechendes „angemessenes Schutzniveau“ für die
        Verarbeitung personenbezogener Daten gewährleisten. Bitte denken Sie an
        diesen Umstand, bevor Sie einen Link anklicken oder ein Social Plug-in
        aktivieren und damit eine Übertragung Ihrer Daten auslösen.{' '}
      </p>
      <p>
        <strong>d.</strong> Für Betrieb, Optimierung und Absicherung unserer
        Webseiten setzen wir außerdem qualifizierte Dienstleister (z. B.
        IT-Dienstleister, Marketing-Agenturen) ein. Personenbezogene Daten geben
        wir an diese nur weiter, soweit dies erforderlich ist für die
        Bereitstellung und Nutzung der Webseiten und deren Funktionalitäten, zur
        Verfolgung berechtigter Interessen, zur Erfüllung rechtlicher
        Verpflichtungen oder soweit Sie darin eingewilligt haben (siehe Ziffer
        7). Nähere Angaben zu den Empfängern finden Sie in unserem Consent
        Management System:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Cookie-Einstellungen öffnen
        </Button>
        .
      </p>

      <h2>5. Webseiten-Technologien</h2>
      <p>
        <strong>a.</strong> Beim Besuch unserer Webseiten können
        Webseiten-Technologien zum Einsatz kommen. Technisch gesehen handelt es
        sich um sog. HTML-Cookies und ähnliche Softwaretools wie Web/DOM Storage
        oder Local Shared Objects (sog. „Flash-Cookies“), die wir zusammen als
        Webseiten-Technologien bezeichnen.
      </p>
      <p>
        <strong>b.</strong> Webseiten-Technologien sind kleine Dateien, die
        während des Besuchs einer Webseite auf Ihrem Desktop-, Notebook- oder
        Mobilgerät abgelegt werden. Daraus kann man z. B. erkennen, ob es
        zwischen dem Gerät und den Webseiten schon eine Verbindung gegeben hat,
        Ihre bevorzugte Sprache oder andere Einstellungen berücksichtigen, Ihnen
        bestimmte Funktionalitäten (z. B. Online-Shop, Fahrzeugkonfigurator)
        anbieten oder nutzungsbasiert Ihre Interessen erkennen.
        Webseiten-Technologien können auch personenbezogene Daten enthalten.
      </p>
      <p>
        <strong>c.</strong> Ob und welche Webseiten-Technologien bei Ihrem
        Besuch unserer Webseiten zum Einsatz kommen, hängt davon ab, welche
        Bereiche und Funktionen unserer Webseiten Sie nutzen und ob Sie dem
        Einsatz von Webseiten-Technologien, die nicht technisch erforderlich
        sind, in unserem Consent Management System zustimmen. Weitere
        Informationen und Entscheidungsmöglichkeiten erhalten Sie hier:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Cookie-Einstellungen öffnen
        </Button>
      </p>
      <p>
        <strong>d.</strong> Der Einsatz von Webseiten-Technologien hängt
        außerdem von den Einstellungen des von Ihnen verwendeten Web-Browsers
        (z. B. Microsoft Edge, Google Chrome, Apple Safari, Mozilla Firefox) ab.
        Die meisten Web-Browser sind so voreingestellt, dass sie bestimmte Arten
        von Webseiten-Technologien automatisch akzeptieren; diese Einstellung
        können Sie jedoch meistens ändern. Vorhandene Webseiten-Technologien
        können Sie jederzeit löschen. Web/DOM-Storage und Local Shared Objects
        können Sie separat löschen. Wie das in dem von Ihnen verwendeten Browser
        bzw. Gerät funktioniert, erfahren Sie in der Anleitung des Herstellers.
      </p>
      <p>
        <strong>e.</strong> Die Einwilligung (= Zustimmung) zu sowie Ablehnung
        oder Löschung von Webseiten-Technologien sind an das verwendete Gerät
        und zudem an den jeweils verwendeten Web-Browser gebunden. Wenn Sie
        mehrere Geräte bzw. Web-Browser verwenden, können Sie die Entscheidungen
        bzw. Einstellungen jeweils unterschiedlich vornehmen.
      </p>
      <p>
        <strong>f.</strong> Wenn Sie sich gegen den Einsatz von
        Webseiten-Technologien entscheiden oder diese löschen, kann es sein,
        dass Ihnen nicht alle Funktionen unserer Webseiten oder einzelne
        Funktionen nur eingeschränkt zur Verfügung stehen.
      </p>

      <h2>6. Sicherheit</h2>
      <p>
        Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um
        Ihre durch uns verwalteten Daten gegen Manipulationen, Verlust,
        Zerstörung und gegen den Zugriff unberechtigter Personen zu schützen.
        Wir verbessern unsere Sicherheitsmaßnahmen fortlaufend entsprechend der
        technologischen Entwicklung.
      </p>

      <h2>7. Rechtsgrundlagen der Verarbeitung</h2>
      <p>
        <strong>a.</strong> Soweit Sie uns für die Verarbeitung Ihrer
        personenbezogenen Daten eine Einwilligung erteilt haben, stellt diese
        die Rechtsgrundlage für die Verarbeitung dar (Art. 6 Abs. 1 Buchst. a
        DSGVO).
      </p>
      <p>
        <strong>b.</strong> Für eine Verarbeitung personenbezogener Daten für
        die Zwecke der Anbahnung oder der Erfüllung eines Vertrages mit Ihnen
        ist Art. 6 Abs. 1 Buchst. b DSGVO die Rechtsgrundlage.
      </p>
      <p>
        <strong>c.</strong> Soweit die Verarbeitung Ihrer personenbezogenen
        Daten zur Erfüllung unserer rechtlichen Verpflichtungen (z.B. zur
        Aufbewahrung von Daten) erforderlich ist, sind wir dazu gemäß Art. 6
        Abs. 1 Buchst. c DSGVO befugt.
      </p>
      <p>
        <strong>d.</strong> Außerdem verarbeiten wir personenbezogene Daten zu
        Zwecken der Wahrnehmung unserer berechtigten Interessen sowie
        berechtigter Interessen Dritter gemäß Art. 6 Abs. 1 Buchst. f DSGVO. Die
        Erhaltung der Funktionsfähigkeit unserer IT-Systeme, die
        (Direkt-)Vermarktung eigener und fremder Produkte und Dienstleistungen
        (soweit diese nicht mit Ihrer Einwilligung erfolgt) und die rechtlich
        gebotene Dokumentation von Geschäftskontakten sind solche berechtigten
        Interessen. Wir berücksichtigen im Rahmen der jeweils erforderlichen
        Interessenabwägung insbesondere die Art der personenbezogenen Daten, den
        Verarbeitungszweck, die Verarbeitungsumstände und Ihr Interesse an der
        Vertraulichkeit Ihrer personenbezogenen Daten.
      </p>

      <h2>8. Löschung Ihrer personenbezogenen Daten</h2>
      <p>
        Ihre IP-Adresse und den Namen Ihres Internet Service Providers, die wir
        aus Sicherheitsgründen speichern, löschen wir nach sieben Tagen. Im
        Übrigen löschen wir Ihre personenbezogenen Daten, sobald der Zweck, zu
        dem wir die Daten erhoben und verarbeitet haben, entfällt. Über diesen
        Zeitpunkt hinaus findet eine Speicherung nur statt, soweit dies gemäß
        den Gesetzen, Verordnungen oder sonstigen Rechtsvorschriften, denen wir
        unterliegen, in der EU oder nach Rechtsvorschriften in Drittstaaten,
        wenn dort jeweils ein angemessenes Datenschutzniveau gegeben ist,
        erforderlich ist. Soweit eine Löschung im Einzelfall nicht möglich ist,
        werden die entsprechenden personenbezogenen Daten mit dem Ziel markiert,
        ihre künftige Verarbeitung einzuschränken.
      </p>

      <h2>9. Betroffenenrechte</h2>
      <p>
        <strong>a.</strong> Als von der Datenverarbeitung betroffene Person
        haben Sie das Recht auf Auskunft (Art. 15 DSGVO), Berichtigung (Art. 16
        DSGVO), Datenlöschung (Art. 17 DSGVO), Einschränkung der Verarbeitung
        (Art. 18 DSGVO) sowie Datenübertragbarkeit (Art. 20 DSGVO).
      </p>
      <p>
        <strong>b.</strong> Haben Sie in die Verarbeitung Ihrer
        personenbezogenen Daten durch uns eingewilligt, haben Sie das Recht, die
        Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der
        Verarbeitung Ihrer personenbezogenen Daten bis zu einem Widerruf wird
        durch den Widerruf nicht berührt. Ebenso unberührt bleibt eine weitere
        Verarbeitung dieser Daten aufgrund einer anderen Rechtsgrundlage, etwa
        zur Erfüllung rechtlicher Verpflichtungen (vgl. Abschnitt
        „Rechtsgrundlagen der Verarbeitung“).
      </p>
      <p
        style={{
          border: '1px solid black',
          borderRadius: '6px',
          padding: '10px',
          margin: '0px -10px 10px -10px',
        }}
      >
        <strong>c.</strong> Widerspruchsrecht
        <br />
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
        personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 e) DSGVO
        (Datenverarbeitung im öffentlichen Interesse) oder Art. 6 Abs. 1 f)
        DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung)
        erfolgt, Widerspruch einzulegen. Legen Sie Widerspruch ein, werden wir
        Ihre personenbezogenen Daten nur weiter verarbeiten, soweit wir dafür
        zwingende berechtigte Gründe nachweisen können, die Ihre Interessen,
        Rechte und Freiheiten überwiegen, oder soweit die Verarbeitung der
        Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
        Sofern wir Ihre personenbezogenen Daten verarbeiten, um Direktwerbung
        zur Wahrnehmung berechtigter Interessen auf der Grundlage einer
        Interessenabwägung zu betreiben, haben Sie zudem das Recht, hiergegen
        jederzeit ohne Nennung von Gründen Widerspruch einzulegen.
      </p>
      <p>
        <strong>d.</strong> Wir bitten Sie, Ihre Ansprüche oder Erklärungen nach
        Möglichkeit an die nachstehende Kontaktadresse zu richten:{' '}
        <a href="mailto:dataprivacy-bus@daimlertruck.com">
          dataprivacy-bus@daimlertruck.com
        </a>
        .
      </p>
      <p>
        <strong>e.</strong> Sind Sie der Ansicht, die Verarbeitung Ihrer
        personenbezogener Daten verstoße gegen gesetzliche Vorgaben, haben Sie
        das Recht zur Beschwerde bei einer zuständigen
        Datenschutzaufsichtsbehörde (Art. 77 DSGVO).
      </p>

      <h2>10. Newsletter</h2>
      <p>
        Wenn Sie einen auf unserer Webseite angebotenen Newsletter abonnieren,
        werden die bei der Newsletter-Anmeldung angegebenen Daten nur für den
        Versand des Newsletters verwendet, soweit Sie nicht einer weitergehenden
        Nutzung zustimmen. Sie können das Abonnement jederzeit über die im
        Newsletter vorgesehene Abmeldemöglichkeit beenden.
      </p>

      <h2>11. Zentraler Zugangsservice der Daimler Truck AG</h2>
      <p>
        Mit dem zentralen Zugangsservice der Daimler Truck AG können Sie sich
        bei allen an diesen Service angeschlossenen Webseiten und Applikationen
        des Daimler-Truck Konzerns und seiner Marken anmelden. Die dafür
        geltenden Nutzungsbedingungen enthalten spezielle Datenschutzregelungen.
        Sie können diese Nutzungsbedingungen auf den jeweiligen Anmeldeseiten
        der angeschlossenen Webseiten und Applikationen abrufen.
      </p>

      <h2>
        12. Datenübertragung an Empfänger außerhalb des Europäischen
        Wirtschaftsraums
      </h2>
      <p>
        <strong>a.</strong> Beim Einsatz von Dienstleistern (siehe Ziffer 4. d.)
        und der Weitergabe von Daten mit Ihrer Einwilligung (= Zustimmung) an
        Dritte (siehe Ziffer 3.c) können personenbezogene Daten an Empfänger in
        Ländern außerhalb der Europäischen Union („EU“), Islands, Liechtensteins
        und Norwegens (= Europäischer Wirtschaftsraum) übertragen und dort
        verarbeitet werden, insbesondere USA, Indien.
      </p>
      <p>
        <strong>b.</strong> In den folgenden Ländern (
        <a
          href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_de"
          target="_blank"
          rel="noreferrer"
        >
          Link zu Liste
        </a>
        ) besteht aus der Sicht der EU ein den EU-Standards entsprechendes
        angemessenes Schutzniveau für die Verarbeitung personenbezogener Daten
        (sog. Angemessenheitsbeschluss). Mit Empfängern in anderen Ländern
        vereinbaren wir die Anwendung von EU-Standardvertragsklauseln, von
        verbindlichen Unternehmensregelungen oder des Swiss-U.S. Privacy Shield,
        um entsprechend den gesetzlichen Anforderungen ein „angemessenes
        Schutzniveau“ zu schaffen. Informationen hierzu stellen wir Ihnen gerne
        über die in vorstehender Ziffer 9.d. genannten Kontaktdaten zur
        Verfügung.
      </p>

      <p>
        <strong>Last update: April 2024</strong>
      </p>
    </div>
  )
}

export default PrivacyDE
