import styled from 'styled-components'
import { Card, Table } from 'antd'

export const PageTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: rgba(73, 80, 87, 1);
  margin: 26px 0px 47px 40px;
`

export const OverviewCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 35px;
  background-color: white;
  height: 95px;
`

export const SmallServer = styled.img`
  height: 85%;
`

export const TableHeader = styled.div``

export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    //margin-top: -6px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  tr {
    background-color: white;
    cursor: pointer;
  }

  th::before {
    display: none;
  }

  ant-table-thead {
    background-color: #fbfafa;
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
  }

  .ant-table-cell {
    padding-top: 12px;
    padding-bottom: 12px;
    width: 20%;
  }

  .ant-table {
    background: #00000000;
  }
  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }

  .ant-col-6 {
    max-width: initial;
  }
`

export const ExpandableTableCard = styled(Card)`
  margin: 3px;
  cursor: default;

  .ant-card-body {
  }
`
