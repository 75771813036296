import { PartNoBg } from '../styledComponents'

const createColumnsSummary = (t) => {
  const columns = [
    // {
    //   title: "Feasible",
    //   dataIndex: "feasible",
    //   className: "feas-col"
    // },
    //Table.EXPAND_COLUMN,
    {
      title: t('Part No'),
      dataIndex: 'id_part_client', //data["id_part_client"],
      //...getColumnSearchProps('id_part_client'),
      render: (title) => <PartNoBg>{title}</PartNoBg>,
    },
    {
      title: t('Part Name'),
      dataIndex: 'name',
      //...getColumnSearchProps('name'),
    },
    {
      title: t('Quantity'),
      //dataIndex: "dimensions",
      //dataIndex: "attributes", //['attributes','0','value'],
      render: () => <>1</>,
    },
    {
      title: t('Price'),
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Price')
          ?.map((row) => row.value)[0],
    },
  ]

  return columns
}

export default createColumnsSummary
