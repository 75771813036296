import { forwardRef } from 'react'
import styled from 'styled-components'
import { Space, Button } from 'antd'

export const ServiceButtonSpace = styled(Space)`
  .ant-space-item {
    flex: 1;
    min-width: 0%;
  }

  .ant-space-item:nth-child(2) {
    flex: 0 0 auto;
    width: 450px; /* Prepare for warehousing card width */
  }
`

export const StyledServicecard = styled.div`
  padding: 16px;
  background-color: ${(props) => props.theme.colorBgLight};
  box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
  width: 100%;

  :hover {
    cursor: ${(props) =>
      props.disabled
        ? 'not-allowed'
        : props.isMetaservice
        ? 'cursor'
        : 'pointer'};
  }
`

export const StyledBookCardTop = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  text-align: right;

  .info_icon {
    font-size: 14px;
    margin-left: 4px;
    cursor: pointer;
    color: ${(props) => props.theme.colorText};
  }

  .info_icon:hover {
    color: ${(props) => props.theme.colorPrimary};
  }
`

export const StyledBookCardBottom = styled.div`
  display: flex;
  flexdirection: row;
  justify-content: space-between;
  flex-direction: row-reverse;
`

export const StyledRibbon = styled.div`
  .ribbon {
    z-index: 1;
    width: 60px;
    height: 60px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #989898;
  }
  .ribbon span {
    margin-top: -1px;
    position: absolute;
    display: block;
    width: 225px;
    background-color: ${(props) => props.theme.colorBgRibbon};
    box-shadow: rgba(0.1, 0.1, 0.1, 0.1) 6px 0px 3px 1.2px;
    color: #fff;
    text-align: center;
  }

  /* top left*/
  .ribbon-top-left {
    bottom: -5px;
    left: -5px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    bottom: 54px;
    left: -4px;
  }
  .ribbon-top-left::after {
    bottom: -4px;
    right: -2px;
  }
  .ribbon-top-left span {
    right: -77px;
    top: 28px;
    transform: rotate(45deg);
  }

  hover {
    box-shadow: 5px 5px 5px #141124;
  }
`

const ButtonComponent = forwardRef(({ ...props }, ref) => (
  <Button ref={ref} {...props} />
))

export const StyledRoundButton = styled(ButtonComponent)`
  padding-top: 9px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colorBgLightEight : props.theme.colorBgIcon};

  border: ${(props) => (props.disabled ? 'none' : 'initial')};
  box-shadow: none;
  cursor: default;
`

export const StyleBookButton = styled(Button)`
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .shortened-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    flex-grow: 1;
  }
`
