import { Table, Tag } from 'antd'

const createColumns = (t) => {
  const columns = [
    {
      title: 'Feasible',
      className: 'feas-col',
      render: (record) => {
        const fCValue = record?.attributes?.find(
          (x) => x.key === 'Feasibility Check',
        )?.value
        const fill =
          fCValue === 'True'
            ? '#34C38F'
            : fCValue === 'False'
            ? '#CD0204'
            : '#F1B44C'
        return (
          <div className="traffic-light">
            <svg viewBox="0 0 12 12">
              <circle cx="60%" cy="60%" r="4" fill={fill} />
            </svg>
          </div>
        )
      },
    },
    Table.EXPAND_COLUMN,

    {
      title: t('Part Name'),
      dataIndex: 'name',
    },
    {
      title: t('Width'),
      //dataIndex: ['attributes','0','value'],
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Width')
          .map((row) => row.value),
    },
    {
      title: t('Depth'),
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Depth')
          .map((row) => row.value),
    },
    {
      title: t('Height'),
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Height')
          .map((row) => row.value),
    },
    {
      title: `${t('Weight')} (g)`,
      render: (record) =>
        record.float_attributes
          .filter((x) => x.key === 'Weight')
          .map((row) => row.value),
    },
    {
      title: t('Material'),
      render: (record) =>
        record.attributes
          .filter((x) => x.key === 'Material')
          .map((row) => row.value),
    },
    {
      title: t('Status'),
      render: (record) => {
        if (record.value !== undefined) {
          if (record.value === true) {
            return <Tag color="green">{t('finished')}</Tag>
          } else {
            return <Tag>{t('unfinished')}</Tag>
          }
        }
      },
    },
    // {
    //   title: "Price AM",
    //   render: (record) => record.float_attributes.filter(x => x.key  == "Price AM").map(row =>row.value),
    // },
  ]

  return columns
}

export default createColumns
