import { StyledLayout } from '../../../globalStyledComponents/Layout'
import Footer from '../../../components/Footer'
import { useTranslation, getI18n } from 'react-i18next'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import PrivacyDE from './PrivacyDE'
import PrivacyEN from './PrivacyEN'

const { Content } = StyledLayout

const DataPrivacy = () => {
  const { t } = useTranslation()

  return (
    <StyledLayout className="layout">
      <Content>
        <div className="header-with-inlines">
          <h1 style={{ margin: '50px 0' }}>{t('data_privacy')}</h1>
          <Link to="/imprint" className="jump">
            <InfoCircleOutlined
              style={{ fontSize: '13px', margin: '4px 8px 0 2px' }}
            />
            {t('Provider')}
          </Link>
          <Link to="/legal-information" className="jump">
            <InfoCircleOutlined
              style={{ fontSize: '13px', margin: '4px 8px 0 2px' }}
            />
            {t('Legal notices')}
          </Link>
        </div>
        <div className="myevocard">
          {getI18n().resolvedLanguage === 'en' ? <PrivacyEN /> : <PrivacyDE />}
        </div>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default DataPrivacy
