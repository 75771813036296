import { Badge } from 'antd'
import styled from 'styled-components'
import { StyledTabs } from '$globalStyledComponents/Tabs'

export const StyledServicesTabs = styled(StyledTabs)`


.ant-progress-circle .ant-progress-text {
        display: none!important;
    }
    
    .ant-tabs-tab {
        border-color: #ffffff!important; 
        width: 100%;
        display: flex;
        justify-content: center;
        padding: unset;
    }

    .ant-tabs-nav-wrap{
        background-color: white;
        box-shadow: 0 0 1.3rem rgb(18 38 63 / 7%);
    }
}
.ant-progress-small.ant-progress-line, .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
    font-size: 12px;
    color:  ${(props) => props.theme.colorPrimary};
}
.ant-tabs-nav-list {
    width: calc(100% - 1px);
    justify-content: space-between;
}

.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 100%;
}

@media screen and (max-width: 1380px) {
    .myservicescontent {
        flex-direction: column-reverse;
    }
    .myserviceskpi { 
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        max-width: 100%;
    }
}
`
export const StyledBadge = styled(Badge)`
  .ant-badge-count {
    line-height: 18px;
    min-width: 18px;
    height: 18px;
  }
`
