import { Collapse, Table } from 'antd'
import styled from 'styled-components'

export const PartNoBg = styled.div`
  background: ${(props) => props.theme.colorBgLightSeven};
  padding: 7px 14px;
  margin: -8px;
  width: fit-content;
  font-weight: 600;
  border-radius: 2px;
  color: ${(props) => props.theme.colorPrimary};
`

export const StyledCollapse = styled(Collapse)`
  border: 1px solid #d5e2e4;
  .ant-collapse-item {
    border-bottom: 1px solid #d5e2e4;
  }
  .ant-collapse-content {
    border-top: 1px solid #d5e2e4;
  }
  .ant-collapse-header {
    background-color: #f1f2f3 !important;
  }
  .ant-collapse-content {
    background-color: #fbfbfb;
  }
  .ant-table-thead {
    background: ${(props) => props.theme.colorBgLightTwo} !important;
  }
`

export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  tr {
    background-color: white;
    //cursor: pointer;
  }

  th::before {
    display: none;
  }

  .feas-col {
    width: 80px;
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #f8f8f8;
  }
  .ant-table-thead .ant-table-row-expand-icon-cell:after {
    content: 'Part No';
  }
  .ant-table-thead .ant-table-row-expand-icon-cell {
    min-width: 60px;
  }
`
