import { Button, Popconfirm, App } from 'antd'
import React, { useState, useEffect } from 'react'
import StepOneSelection from '../Defaultwizard/StepOneSelection'
import StepTwoBrandingConfig from './StepTwoBrandingConfig'
import StepThreeSummary from '../Defaultwizard/StepThreeSummary'
import StatusPage from '../Defaultwizard/StatusPage'
import { updateStatusAttributes } from '$utils'
import {
  StyledSteps,
  StyledStepButtons,
} from '../Defaultwizard/styledComponents'
import {
  fetchAllServices,
  postApplyBranding,
  postPartsConsolidation,
} from '$api/evoAPIs'
import { putPart } from '$api/client'
import { evoConsolidation } from '$api'
import { evoClient } from '$api/client'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

const Applybranding = (props) => {
  const { t } = useTranslation()
  const [fcData] = useState({
    not_feasible_parts: [],
    feasible_parts: [],
  })
  const [bookableParts, setBookableParts] = useState()
  const [steps, setSteps] = useState([
    {
      title: t('service.selection'),
      content: '',
    },
    {
      title: t('service.apply_branding.step_config_title'),
      content: '',
    },
    {
      title: t('service.order'),
      content: '',
    },
  ])
  const [current, setCurrent] = useState(0)

  const { message } = App.useApp()
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(true)
  const [configState, setConfigState] = useState(true)
  const [currentFormValues, setCurrentFormValues] = useState() // the current saved Apply-Branding-Data
  const [accumulatedFormData, setAccumulatedFormData] = useState([])
  const [currentApplyBrandingId, setCurrentApplyBrandingId] = useState()
  const [stlFile, setSTLFile] = useState()
  const [stlFileName, setSTLFileName] = useState()
  const [imgFile, setImgFile] = useState()
  const [imgFileName, setImgFileName] = useState()

  const queryClient = useQueryClient()
  const { data: servicesData } = useQuery(['services'], fetchAllServices)

  const addBookedApplyBrandings = useMutation(
    (formData) => {
      formData.service_name = 'Apply Branding'
      formData.service_id = props.bookedServiceStatus[1].id
      formData.partlist_name = props.activeGroupName
      formData.part.files = []
      setSTLFile(formData.stl_file)
      setImgFile(formData.img_file)
      delete formData.stl_file
      delete formData.img_file
      return evoConsolidation.post(postApplyBranding, formData, {
        headers: {
          'content-type': 'application/json',
        },
      })
    },
    {
      onSuccess: (data) => {
        setCurrentApplyBrandingId(data.data.id)
        // upload STL & Branding Image File
        uploadSTLFile.mutate(data.data.part.db_id_client)
        uploadBrandingImage.mutate(data.data.part.db_id_client)
        // update Part status attribute
        updateClientPartStatus.mutate(data.data)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(err.response.status + ' Could not book Service')
      },
    },
  )

  const uploadSTLFile = useMutation(
    (conslidation_part_id) => {
      const formData = new FormData()
      const file = new File([stlFile], 'dateiname.stl', {
        type: 'application/vnd.ms-pki.stl',
      })
      formData.append('file', file)
      return evoConsolidation.post(
        postPartsConsolidation + conslidation_part_id + '/file',
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
    },
    {
      onSuccess: (data) => {
        //message.success("Service booked")
        setSTLFileName(data.data)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(err.response.status + ' Could Upload STL File')
      },
    },
  )

  const uploadBrandingImage = useMutation(
    (conslidation_part_id) => {
      const formData = new FormData()
      formData.append('file', imgFile, 'name.png')
      return evoConsolidation.post(
        postPartsConsolidation + conslidation_part_id + '/file',
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
    },
    {
      onSuccess: (data) => {
        //message.success("Service booked")
        setImgFileName(data.data)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(err.response.status + ' Could Upload Branding Image')
      },
    },
  )

  const patchBrandingFiles = useMutation(
    (data) => {
      const body = JSON.stringify([data[1], data[2]])
      const applybrandingid = data[0]
      return evoConsolidation.patch(postApplyBranding + applybrandingid, body, {
        headers: {
          'content-type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        //message.success("Service booked")
        setLoading(false)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(err.response.status + ' Could Upload Branding Image')
      },
    },
  )

  const updateClientPartStatus = useMutation(
    (partsToUpdate) => {
      //check if "partsToUpdate" is from feasibility check or booked service
      if (partsToUpdate.part) {
        const editedPart = updateStatusAttributes(
          partsToUpdate.part,
          'status_attributes',
          'Apply Branding',
          '1',
        )
        return evoClient.put(putPart + editedPart.db_id_client, editedPart, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      }
    },
    {
      onSuccess: () => {
        //setFcData(data.data);
        queryClient.invalidateQueries(['allgroups']) //check
        queryClient.invalidateQueries(['notifications'])
      },
      onError: (err) => {
        message.error(err.response.status + ', AAAAAAAHHHHH')
      },
    },
  )

  useEffect(() => {
    if (bookableParts || servicesData) {
      setSteps([
        {
          title: t('service.selection'),
          content: (
            <StepOneSelection
              fcData={fcData}
              selectedParts={props.selectedParts}
              setBookableParts={setBookableParts}
              setConfigState={setConfigState}
              bookableParts={bookableParts}
              servicesData={servicesData}
              bookedServiceStatus={props.bookedServiceStatus}
            />
          ),
        },
        {
          title: t('service.apply_branding.step_config_title'),
          content: (
            <StepTwoBrandingConfig
              fcData={fcData}
              selectedParts={props.selectedParts}
              setBookableParts={setBookableParts}
              bookableParts={bookableParts}
              setConfigState={setConfigState}
              configState={configState}
              setCurrentFormValues={setCurrentFormValues}
            />
          ),
        },
        {
          title: t('service.order'),
          content: (
            <StepThreeSummary
              fcData={fcData}
              selectedParts={props.selectedParts}
              bookableParts={bookableParts}
              setButtonActive={setButtonActive}
            />
          ),
        },
      ])
    }
  }, [
    bookableParts,
    configState,
    fcData,
    props.bookedServiceStatus,
    props.selectedParts,
    servicesData,
    t,
  ])

  useEffect(() => {
    if (currentFormValues) {
      // Put Forms from all Parts in an Array. Check if the current one exists, then update, or if not, append it. Checks are based on id
      setAccumulatedFormData(
        accumulatedFormData.length
          ? accumulatedFormData.some(
              (item) =>
                item.part.db_id_client === currentFormValues.part.db_id_client,
            )
            ? accumulatedFormData.map((item) =>
                item.part.db_id_client === currentFormValues.part.db_id_client
                  ? currentFormValues
                  : item,
              )
            : [...accumulatedFormData, currentFormValues]
          : [currentFormValues],
      )
    }
  }, [currentFormValues]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (imgFileName && stlFileName) {
      const nameArr = [currentApplyBrandingId, imgFileName, stlFileName]
      patchBrandingFiles.mutate(nameArr)
    }
  }, [imgFileName, stlFileName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bookableParts && bookableParts.bookableParts.length === 0) {
      setConfigState(false)
    }
  }, [bookableParts])

  const handleOk = () => {
    setCurrent(0)
    props.setSelectedParts([])
    setButtonActive(true)

    //post to endpoint
    for (let formData of accumulatedFormData) {
      addBookedApplyBrandings.mutate(formData)
    }
    setLoading(true)
    setVisible(false)
  }

  const next = () => {
    if (current === 0) {
      setConfigState(false)
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    if (current === 1) {
      setConfigState(true)
    }
    setCurrent(current - 1)
  }
  const items = steps
    .filter(function (x) {
      return x !== null
    })
    .map((item) => ({
      key: item.title,
      title: item.title,
    }))

  return (
    <>
      {visible && (
        <>
          <h1 style={{ margin: '50px 0' }}>
            {t(props.bookedServiceStatus[1].servicename)}
          </h1>
          <div className="contentcard">
            <StyledSteps current={current} items={items} type="navigation" />
            <div style={{ margin: '50px 0px' }}>{steps[current].content}</div>
            <StyledStepButtons>
              {current < steps.length - 1 && (
                <Popconfirm
                  disabled={configState}
                  placement="topRight"
                  description={
                    <>
                      Some parts need configuration before proceeding.
                      <br />
                      Check the "status" of your part.
                    </>
                  }
                  title={<h4>Some changes have to be made</h4>}
                >
                  <Button
                    type="primary"
                    onClick={() => next()}
                    disabled={!configState}
                  >
                    {t('button.next')}
                  </Button>
                </Popconfirm>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => handleOk()}
                  disabled={!buttonActive}
                >
                  {t('button.order')}
                </Button>
              )}
              {current > 0 && (
                <Button
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prev()}
                >
                  {t('button.back')}
                </Button>
              )}
            </StyledStepButtons>
          </div>
        </>
      )}
      {!visible && (
        <>
          <StatusPage
            setIsBookModalOpen={props.setIsBookModalOpen}
            loading={loading}
            setCurrent={props.setCurrent}
          />
        </>
      )}
    </>
  )
}

export default Applybranding
