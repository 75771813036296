import { setOrderPartRejectedByClient } from '$api/evoAPIs'
import { CenteredSpinner } from '$components/Spinner'
import { PartNoBg } from '$pages/serviceuser/Marketplace/Bookservice/Defaultwizard/styledComponents'
import { StyledTable } from './styledComponents'
import { DeleteOutlined } from '@ant-design/icons'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { Popconfirm, Tag } from 'antd'
import { useTranslation } from 'react-i18next'
import { calculateOrderPartPrice, summarizeDeliveryTimes } from '../utils'
import OrderPartDetailView from './OrderPartDetailView'
import { StyledLink } from './styledComponents'
import { currencyRenderer } from '$utils/i18n'
import useMaterialMapping from '$utils/useMaterialMapping'

const OrderedPartsTable = ({
  orderPartsList,
  orderPartFormData,
  resetOrderPartFormData,
  orderPartListStatus,
  loading,
  editable = false,
  showProviderOffers,
  showRejectedProviderOffers,
  showOfferTerms,
  additionalColumns = [],
}) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  if (!orderPartsList.provider_accepted_offer) {
    editable = false
  }

  // once client has accepted offer, rejected parts of offer should
  // - still be rendered
  // - be visually recognizable as rejected
  // - be excluded from price calculations etc.
  const disableRejectedProviderOffers =
    orderPartsList.client_accepted_offer && showRejectedProviderOffers

  const allowOrderPartReject =
    orderPartsList.list_of_orderparts.filter(
      (orderPart) => !orderPart.rejected_by_client,
    ).length > 1

  const validOrderParts = showRejectedProviderOffers
    ? orderPartsList.list_of_orderparts
    : orderPartsList.list_of_orderparts.filter(
        (orderPart) => !orderPart.rejected_by_client,
      )

  const { data: materialsById, isLoading: materialsAreLoading } =
    useMaterialMapping()

  const rejectOfferMutation = useMutation({
    mutationFn: setOrderPartRejectedByClient,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allorderedpartslists'] })
    },
  })

  const handleDelete = (orderPartId) => {
    if (allowOrderPartReject) {
      rejectOfferMutation.mutate({
        order_part_id: orderPartId,
        rejected_by_client: true,
      })
    }
  }

  const defaultColumns = [
    // this is for space
    ...(!showOfferTerms
      ? [
          {
            title: '',
          },
        ]
      : []),
    {
      title: t('Part No'),
      render: (record) => (
        <PartNoBg
          disabled={disableRejectedProviderOffers && record.rejected_by_client}
        >
          {record.part.id_part_client}
        </PartNoBg>
      ),
    },
    {
      title: t('Part Name'),
      dataIndex: ['part', 'name'],
    },
    {
      title: t('Amount'),
      render: (record) => {
        if (editable) {
          return Object.values(orderPartFormData[record.id].provider_offers)
            .map((offer) => offer.amount)
            .reduce((partialSum, a) => partialSum + a, 0)
        } else {
          return orderPartsList.main_order.orderpart_quantity[record.id]
        }
      },
    },
    {
      title: t('Material'),
      dataIndex: ['material'],
      render: (record) => (
        <>
          {materialsById[record].name}{' '}
          {/* {editable && (
            <StyledLink onClick={() => showModal()}>
              <EditOutlined />
            </StyledLink>
          )} */}
        </>
      ),
    },
    {
      title: t('my_orders.delivery_time'),
      render: (record) => {
        if (!showOfferTerms) {
          return
        }

        let deliveryTimes = []
        if (editable) {
          deliveryTimes = Object.values(
            orderPartFormData[record.id].provider_offers,
          )
            .map((offer) => offer.delivery_days)
            .filter((days) => days)
        } else {
          deliveryTimes = record.provider_offers
            .map((offer) =>
              orderPartsList.client_accepted_offer
                ? offer.delivery_days
                : [
                    offer.base_production_time,
                    ...offer.production_time_rules.map((rule) => rule.max_days),
                  ],
            )
            .flat()
        }
        return summarizeDeliveryTimes(deliveryTimes)
      },
    },
    {
      title: t('my_orders.price_total'),
      render: (record) => {
        if (!showOfferTerms) {
          return
        }

        let price
        if (editable) {
          price = calculateOrderPartPrice(
            Object.values(orderPartFormData[record.id].provider_offers),
          )
        } else {
          price = calculateOrderPartPrice(
            record.provider_offers,
            orderPartsList.client_accepted_offer,
          )
        }

        return currencyRenderer(t)(price)
      },
    },
    ...(editable
      ? [
          {
            title: t('Status'),
            render: (record) => {
              if (
                orderPartListStatus?.[orderPartsList.id]?.includes(record.id)
              ) {
                return (
                  <Tag color="success">
                    {t('service.order_part.status.reviewed')}
                  </Tag>
                )
              } else {
                return <Tag>{t('service.order_part.status.open')}</Tag>
              }
            },
          },
          {
            title: '',
            render: (record) =>
              allowOrderPartReject && (
                <Popconfirm
                  title={t('service.order_part.status.confirm_delete')}
                  onConfirm={() => handleDelete(record.id)}
                >
                  <StyledLink>
                    <DeleteOutlined />
                  </StyledLink>
                </Popconfirm>
              ),
          },
        ]
      : []),
  ]

  if (materialsAreLoading) {
    return <CenteredSpinner />
  }

  return (
    <StyledTable
      size="middle"
      columns={[...defaultColumns, ...additionalColumns]}
      style={{ width: '100%' }}
      dataSource={validOrderParts}
      loading={loading}
      rowKey={(record) => record.id}
      rowClassName={(record) =>
        disableRejectedProviderOffers && record.rejected_by_client
          ? 'row-disabled'
          : undefined
      }
      expandable={
        showProviderOffers
          ? {
              showExpandColumn: true,
              expandRowByClick: true,
              expandedRowClassName: (record) =>
                disableRejectedProviderOffers && record.rejected_by_client
                  ? 'expanded-row-disabled'
                  : undefined,
              expandedRowRender: (record) => (
                <OrderPartDetailView
                  orderPartListId={orderPartsList.id}
                  clientAcceptedOffer={orderPartsList.client_accepted_offer}
                  orderPart={record}
                  resetFormData={() => resetOrderPartFormData(record.id)}
                  editable={editable}
                />
              ),
            }
          : false
      }
    />
  )
}
export default OrderedPartsTable
