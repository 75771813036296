import CustomInputNumber from '$components/CustomInputNumer'
import CustomRule from '$components/Services/ReceivedRequests/CustomRule'
import Tooltip from '$components/Tooltip'
import { PlusOutlined } from '@ant-design/icons'
import { UilQuestionCircle } from '@iconscout/react-unicons'
import { Button, Flex, Space } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Form from '$components/Form'

const CustomizeRulesForm = ({
  typeOfRules,
  orderPartId,
  providerOffer,
  resetInvalidProviderOffers,
  deleteRule: deleteRuleForCalculation,
}) => {
  const providerOfferId = providerOffer.offer_id

  const { t } = useTranslation()
  const form = Form.useFormInstance()

  const [rules, setRules] = useState(
    Object.keys(
      {
        price: providerOffer.price_rules,
        delivery: providerOffer.production_time_rules,
      }[typeOfRules] || {},
    ),
  )

  const addRule = () => {
    setRules([...rules, crypto.randomUUID()])
  }
  const deleteRule = (id) => {
    setRules(rules.filter((ruleId) => ruleId !== id))
    resetInvalidProviderOffers()
    deleteRuleForCalculation({
      orderPartId,
      offerId: providerOffer.offer_id,
      typeOfRules,
      ruleId: id,
    })
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <Flex
        justify={'space-between'}
        align={'center'}
        className="ant-list-sm ant-list-item"
        style={{ padding: '7px 10px' }}
      >
        <Flex align={'center'}>
          <Space>
            <Form.Item
              label={
                typeOfRules === 'price'
                  ? t('received_requests.base_price')
                  : t('received_requests.base_production_time')
              }
              name={[
                orderPartId,
                'provider_offers',
                providerOfferId,
                { price: 'base_price', delivery: 'base_production_time' }[
                  typeOfRules
                ],
              ]}
              rules={[
                {
                  required: true,
                  type: 'number',
                  min: typeOfRules === 'price' ? 0.01 : 1,
                },
              ]}
            >
              <CustomInputNumber
                size="small"
                addonAfter={typeOfRules === 'price' ? '€' : null}
                style={{ width: '90px' }}
                precision={typeOfRules === 'price' ? 2 : 0}
                min={typeOfRules === 'price' ? 0.01 : 1}
                onChange={() => {
                  if (typeOfRules === 'price') {
                    // validate all corresponding discount amount fields
                    form.validateFields(
                      [
                        [
                          orderPartId,
                          `provider_offers`,
                          providerOfferId,
                          'price_rules',
                        ],
                      ],
                      {
                        recursive: true,
                      },
                    )
                  }
                }}
              />
            </Form.Item>
            {typeOfRules === 'price' ? null : t('days')}
            <Tooltip
              placement="top"
              title={
                typeOfRules === 'price'
                  ? 'This is the base price for all discount rules.'
                  : 'This is the base production time for all configurable production time rules.'
              }
            >
              <UilQuestionCircle size={14} style={{ marginTop: '5px' }} />
            </Tooltip>
          </Space>
        </Flex>
        <Button size="small" onClick={addRule}>
          <PlusOutlined />
        </Button>
      </Flex>

      {rules.map((ruleId) => (
        <CustomRule
          key={ruleId}
          typeOfRules={typeOfRules}
          orderPartId={orderPartId}
          providerOfferId={providerOfferId}
          ruleId={ruleId}
          deleteRule={deleteRule}
        />
      ))}
    </div>
  )
}

export default CustomizeRulesForm
