import { useEffect, useState, useContext } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Modal, Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AuthContext } from '$context/AuthContextProvider'
import { AUTOMATIC_LOGOUT } from '$constants'

const msToSeconds = (ms) => Math.floor(ms / 1_000)
const minsToMs = (mins) => mins * 60 * 1_000

const UserIdleHandler = ({
  children,
  idleTimeoutMinutes = 60,
  promptBeforeIdleMinutes = 5,
}) => {
  const timeout = minsToMs(idleTimeoutMinutes)
  const promptBeforeIdle = minsToMs(promptBeforeIdleMinutes)

  const authContext = useContext(AuthContext)
  const { t } = useTranslation()
  const location = useLocation()

  const [remaining, setRemaining] = useState<number>(msToSeconds(timeout))
  const [open, setOpen] = useState<boolean>(false)

  const onIdle = async () => {
    if (authContext.isAuthenticated) {
      await handleLogout(AUTOMATIC_LOGOUT, location.pathname)
    }
  }

  const onActive = () => {
    setOpen(false)
  }

  const onPrompt = () => {
    if (authContext.isAuthenticated) {
      setOpen(true)
    }
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(msToSeconds(getRemainingTime()))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleLogout = async (logoutFlag?: string, redirectPath?: string) => {
    await authContext.logout(logoutFlag, redirectPath)
    setOpen(false)
  }

  const handleStillHere = () => {
    activate()
  }

  return (
    <>
      {children}
      <Modal
        title={t('logout_impending_title')}
        open={open}
        closable={false}
        footer={[
          <Button key="back" onClick={() => handleLogout()}>
            {t('Logout')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleStillHere}>
            {t('still_here')}
          </Button>,
        ]}
      >
        <p>
          {remaining >= 60
            ? t('logout_impending_text', {
                timeLeft: Math.ceil(remaining / 60),
                range: 'minutes',
              })
            : t('logout_impending_text', {
                timeLeft: remaining,
                range: 'seconds',
              })}
        </p>
      </Modal>
    </>
  )
}

export default UserIdleHandler
