import { Button, Modal } from 'antd'
import { useState } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { evoClient } from '$api/client'
import PartsTable from '$components/PartsTable'

const FilterAddPartsModal = (props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [selectedParts, setSelectedParts] = useState({})
  const [searchValue, setSearchValue] = useState([])

  const addPartsToGroupMutation = useMutation(
    (partIds) => {
      return evoClient.put(`/parts-lists/${props.groupId}/parts`, partIds, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['groupparts'])
        queryClient.invalidateQueries(['parts'], { groupId: props.groupId })
        props.setIsModalOpen(false)
        setSelectedParts({})
        let text = dayjs().format('DD.MM.YYYY').toString()
        for (let i of searchValue) {
          text +=
            ' ' + i.parameter + ' ' + i.logical_operator + ' ' + i.value + ', '
        }
        props.setGroupFilterDescription(text)
        setSearchValue([])
      },
    },
  )

  const showModal = () => {
    props.setIsModalOpen(true)
  }
  const handleOk = () => {
    addPartsToGroupMutation.mutate(Object.keys(selectedParts))
  }
  const handleCancel = () => {
    props.setIsModalOpen(false)
    setSelectedParts({})
    setSearchValue([])
  }
  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t('parts_master_data.add_parts.button_text')}
      </Button>
      <Modal
        title={t('parts_master_data.add_parts.title')}
        open={props.isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={1200}
        centered
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel} size="large">
            {t('button.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk} size="large">
            {t('parts_master_data.add_parts.submit')}
          </Button>,
        ]}
      >
        <p style={{ marginTop: '-14px' }}>
          {t('parts_master_data.add_parts.info')}
        </p>
        <PartsTable
          columns={[
            'feasible',
            'part_id',
            'name',
            'width',
            'depth',
            'height',
            'weight',
            'material',
            'price',
            'price_am',
            'created_at',
            'updated_at',
          ]}
          selectable={{ selectedParts, setSelectedParts }}
          filterable={{
            columns: [
              'part_id',
              'name',
              'width',
              'depth',
              'height',
              'weight',
              'material',
            ],
            filters: searchValue.reduce(
              // TODO: Consistently use new format of search object instead
              //   see VBS-1201 and [MR !238](https://gitlab.com/susiandjames/projects/evobus/dwh/evobus-ui/-/merge_requests/238)
              (searchObj, search) => ({
                ...searchObj,
                [search.parameter]: [
                  ...(searchObj[search.parameter] || []),
                  search,
                ],
              }),
              props.groupId
                ? {
                    partlist_id: [
                      {
                        parameter: 'partlist_id',
                        logical_operator: '!=',
                        value: props.groupId,
                      },
                    ],
                  }
                : {},
            ),
          }}
        />
      </Modal>
    </>
  )
}
export default FilterAddPartsModal
