import styled from 'styled-components'
import { Table } from 'antd'

export const StyledPeriods = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDiscount = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  :hover {
    background: #f5f5f5;
  }
`

export const StyledLink = styled.span`
  cursor: pointer;
`

export const StyledTable = styled(Table)`
  /* Apply base table styles */
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  /* Cursor for main table rows */
  tr.ant-table-row {
    background-color: white;
    cursor: pointer;
  }

  /* Override for expanded rows and any nested tables within them */
  tr.ant-table-expanded-row,
  tr.ant-table-expanded-row table tr,
  tr.ant-table-expanded-row table tr.ant-table-row {
    cursor: default;
  }

  /* Apply cursor pointer specifically to first-level inner styled table rows */
  tr.ant-table-expanded-row table tr.ant-table-row {
    cursor: pointer;
  }

  /* Ensure all deeper nested tables, like unstyled tables, have default cursor */
  tr.ant-table-expanded-row table tr.ant-table-expanded-row,
  tr.ant-table-expanded-row table tr.ant-table-expanded-row table tr {
    cursor: default;
  }

  /* Additional styling */
  th::before {
    display: none;
  }
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > td:hover {
    border-bottom: none;
    background: none;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: none;
  }
  .ant-form-item .ant-form-item-control-input {
    min-height: fit-content;
  }
`
