import { PartNoBg } from '../styledComponents'

const createColumnsSummary = (t, materialsById) => {
  const columns = [
    {
      title: t('Part No'),
      dataIndex: ['part', 'id_part_client'],
      render: (title) => <PartNoBg>{title}</PartNoBg>,
    },
    {
      title: t('Part Name'),
      dataIndex: ['part', 'name'],
    },
    {
      title: t('Material'),
      render: (record) => materialsById[record.material_id]?.name,
    },
    {
      title: t('Quantity'),
      dataIndex: 'amount',
    },
  ]

  return columns
}

export default createColumnsSummary
