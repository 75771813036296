import DreiDModelConfigTable from './3DModelConfigTable'
import { useTranslation } from 'react-i18next'

const StepTwo3DModelConfig = (props: any) => {
  const { t } = useTranslation()

  return (
    <>
      <h2 style={{ margin: '50px 0' }}>
        {t('service.generate_3d_model.step_config_title')}
      </h2>
      <DreiDModelConfigTable
        bookableParts={props.bookableParts}
        setCurrentFormValues={props.setCurrentFormValues}
        setConfigState={props.setConfigState}
        configState={props.configState}
      />
      <div style={{ marginTop: '20px' }}></div>
    </>
  )
}

export default StepTwo3DModelConfig
