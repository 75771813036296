import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  GroupedDiv,
  StyledDiv,
  StyledTable,
  StyledTableDWHM,
  StyledSubTableDWHM,
} from './styledComponents'
import { TableRowSelection } from 'antd/lib/table/interface'
import type { ColumnsType } from 'antd/es/table'
import type { TableColumnsType } from 'antd'
import { Button, Result, Table } from 'antd'
import createColumnsCM from './createColumnsCM'
import createColumnsDWHM from './createColumnsDWHM'

interface CustomTableRowSelection extends TableRowSelection<ColumnsType> {
  onChange: (selectedRowKeys: React.Key[], selectedRows: ColumnsType[]) => void
}

interface DataTypeCM {
  key: string
  name: string
  count: string
  dwhmaterial: string
}
interface Props {
  setSelectedRowKeys: Dispatch<SetStateAction<React.Key[]>>
  selectedRowKeys: React.Key[]
}

const tData = (data) =>
  Array.isArray(data) && data?.map((el, i) => ({ key: i, ...el }))

const ClientMaterials = (props: Props) => {
  const [cmData, setCMData] = useState<DataTypeCM[]>([
    {
      key: 'plastik',
      name: 'Plastik',
      count: '11',
      dwhmaterial: 'PA12',
    },
    {
      key: 'metall',
      name: 'Metall',
      count: '5',
      dwhmaterial: 'Stahl PLA12',
    },
    {
      key: 'stein',
      name: 'Stein',
      count: '1',
      dwhmaterial: '',
    },
  ])

  const onRow = (record: DataTypeCM) => {
    return {
      onClick: () => {
        setSelectedRow(record)
        props.setSelectedRowKeys([record.key])
        setSelectedRowKeys([record.key])
        // props.setUserID(record.id)
      },
    }
  }
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([
    'plastik',
  ])
  const [selectedRow, setSelectedRow] = useState<DataTypeCM | undefined>(
    undefined,
  )
  const rowSelection: TableRowSelection<DataTypeCM> = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <>
      <StyledTable
        columns={createColumnsCM()}
        dataSource={tData(cmData)}
        rowSelection={rowSelection}
        onRow={onRow}
        rowKey={(record) => record.key}
      />
      {/* <p>Selected: {selectedRow ? selectedRow.name : "None"}</p> */}
    </>
  )
}

export default ClientMaterials
