import { useState, useEffect } from 'react'
import { List, Tag, Popconfirm, Row, Col, Divider, App } from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  CalendarOutlined,
} from '@ant-design/icons'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { StyledCard } from '$globalStyledComponents/Card'
import { evoKeycloak } from '$api'

const Subcompanies = (props) => {
  const [data, setData] = useState([])
  const [mainCompany, setMainCompany] = useState('')
  const queryClient = useQueryClient()
  const { notification } = App.useApp()
  const deleteCompanyMutation = useMutation(
    async (id) => {
      if (id !== mainCompany.id) {
        return evoKeycloak.delete(`/admin/realms/EVO/groups/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } else {
        throw new Error('Operation not possible')
      }
    },
    {
      onSuccess: () => {
        openSuccessNotification('Company deleted')
        queryClient.invalidateQueries(['company'])
      },
      onError: (e) => {
        console.error(e)
      },
    },
  )

  const openSuccessNotification = (msg) => {
    notification.success({
      message: 'Success',
      description: msg,
      onClick: () => {},
      placement: 'top',
    })
  }

  const onDelete = (item) => {
    deleteCompanyMutation.mutate(item.id)
  }

  const onEdit = (item) => {
    props.setCompanyID(item.id)
  }
  useEffect(() => {
    if (props.company && props.company.subGroups.length > 0) {
      async function fetchUserCount() {
        let subCompanies = props.company.subGroups
        const updatedResultArray = await Promise.all(
          subCompanies.map(async (company) => {
            const secondResponse = await evoKeycloak.get(
              `/admin/realms/EVO/groups/${company.id}/members`,
            )

            return {
              ...company,
              userCount: secondResponse.data.length,
              created: secondResponse.data[0]
                ? new Date(secondResponse.data[0].createdTimestamp)
                    .toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                    .replace(/\//g, '.')
                : new Date(secondResponse.data?.createdTimestamp * 1000)
                    ?.toLocaleDateString('en-US', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                    .replace(/\//g, '.'),
            }
          }),
        )
        setData(updatedResultArray)
      }
      setMainCompany(props.company.name)
      fetchUserCount()
    }
  }, [props.company])

  return props.company && props.company.subGroups?.length > 0 ? (
    <>
      <h3 style={{ paddingLeft: '22px', margin: '20px 0px 0px' }}>
        Sub Companies
      </h3>
      <br />
      <List
        style={{ maxWidth: '870px' }}
        grid={{ gutter: 16, column: 2 }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item style={{ padding: '0px 0px' }}>
            <StyledCard
              actions={[
                <EditOutlined key="edit" onClick={() => onEdit(item)} />,
                <Popconfirm
                  placement="top"
                  title="Delete Company"
                  description={`sure you want to delete company "${item.name}" permanently?`}
                  onConfirm={() => onDelete(item)}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined key="ellipsis" />
                </Popconfirm>,
              ]}
              hoverable={false}
            >
              <Row>
                <Col span={6}>
                  <Tag
                    color="orange"
                    style={{
                      width: '70px',
                      height: '70px',
                      borderRadius: '50%',
                      margin: '3px 0px 0px 0px',
                      opacity: '0.8',
                    }}
                  >
                    <span
                      className="absolutecenter"
                      style={{ fontSize: 'large' }}
                    >
                      {item?.name[0]}
                    </span>
                  </Tag>
                </Col>
                <Col span={18}>
                  <div style={{ padding: '0 24px' }}>
                    <h3 className="text-primary">{item?.name}</h3>
                    <p>
                      {item?.attributes?.addressline1[0] +
                        ', ' +
                        item?.attributes?.postalcode[0] +
                        ' ' +
                        item?.attributes?.country[0]}
                    </p>
                    <Tag bordered={false}>SUB</Tag>
                    <Divider type="vertical" />
                    <CalendarOutlined /> {item.created}
                    <Divider type="vertical" />
                    <UserOutlined />
                    {'  '} {item.userCount}
                  </div>
                </Col>
              </Row>
            </StyledCard>
          </List.Item>
        )}
      />
    </>
  ) : (
    <></>
  )
}
export default Subcompanies
