import PrinterImg from '$assets/img/3DPrinter2.png'
import DateRangePicker from '$components/DateRangePicker'
import { StyledCard } from '$globalStyledComponents/Card'
import { useQueryClient } from '@tanstack/react-query'
import {
  Button,
  Col,
  Divider,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Segmented,
  Select,
} from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { numberRenderer } from '$utils/i18n'
import Form from '$components/Form'
import { StyledRow, StyledRow2 } from './styledComponents'

const Printerform = (props) => {
  const { t } = useTranslation()
  const formRef = React.useRef(null)
  const [materialOptions, setMaterialOptions] = useState([])
  const [active, setActive] = useState('publicly available')

  const queryClient = useQueryClient()

  const calculateVolume = useCallback(() => {
    if (formRef.current) {
      const { max_width, max_height, max_depth } =
        formRef.current?.getFieldsValue([
          'max_width',
          'max_height',
          'max_depth',
        ])
      if (max_width > 0 && max_height > 0 && max_depth > 0) {
        const calculatedVolume = (max_width * max_height * max_depth) / 1000 // converting cubic mm to cubic dm (liters)
        props.setVolume(parseFloat(calculatedVolume.toFixed(2)))
      } else {
        props.setVolume(0)
      }
    }
  }, [props])

  const onReset = () => {
    formRef.current?.resetFields()
    props.setFormHasTouched(false)
  }

  const onCancel = () => {
    if (props.newPrinter) {
      formRef.current?.resetFields()
      props.setNewPrinter(false)
      props.setFormHasTouched(false)
      queryClient.resetQueries(['printer'])
      return
    }
  }
  const onDelete = () => {
    props.setFormHasTouched(false)
    if (props.newPrinter) {
      formRef.current?.resetFields()
      return
    } else {
      props.onDelete(props.printerID)
    }
  }
  const onFormChange = () => {
    if (!props.formHasTouched) {
      props.setFormHasTouched(true)
    }
    calculateVolume()
  }
  const onFinish = (values) => {
    props.setFormHasTouched(false)
    const unflatten = (flatObject) =>
      Object.entries(flatObject).reduce((result, [key, value]) => {
        key
          .split('.')
          .reduce(
            (obj, k, i, keys) =>
              (obj[k] = obj[k] || (i === keys.length - 1 ? value : {})),
            result,
          )
        return result
      }, {})
    props.onFinish(unflatten(values))
  }

  useEffect(() => {
    const flatten = (o, prefix = '') => {
      if (!o || typeof o !== 'object') return [] // Ensure o is a valid object
      return Object.entries(o).flatMap(([k, v]) =>
        v && typeof v === 'object'
          ? flatten(v, `${prefix}${k}.`)
          : [[`${prefix}${k}`, v]],
      )
    }

    if (
      props.printerID &&
      !props.newPrinter &&
      props.printerFormData &&
      props.printerFormData?.items?.length > 0
    ) {
      const currentPrinter = props.printerFormData?.items?.find(
        (obj) => obj.id === props.printerID,
      )
      formRef.current?.setFieldsValue(
        Object.fromEntries(flatten(currentPrinter)),
      )
      formRef.current?.setFieldValue('materials', currentPrinter.materials)
      formRef.current?.setFieldValue(
        'current_material.name',
        currentPrinter.current_material,
      )
      formRef.current?.setFieldValue('verfuegbarkeit', [
        currentPrinter.verfuegbarkeit[0],
        currentPrinter.verfuegbarkeit[1],
      ])
    }
  }, [props.printerID, props.printerFormData, props.newPrinter])

  useEffect(() => {
    if (props.newPrinter) {
      formRef.current?.resetFields()
    } else {
      //props.setPrinterID(data[0]?.id)
    }
  }, [props.newPrinter])

  useEffect(() => {
    if (props.materials) {
      setMaterialOptions(
        props.materials.map((obj) => ({
          label: obj.name,
          value: obj.name,
        })),
      )
    }
  }, [props.materials])

  useEffect(() => {
    calculateVolume()
  }, [props.printerID, calculateVolume])

  return (
    <StyledCard>
      <h3>{t('my_printers.printer_form_title')}</h3>
      <br /> {/* <- shame on me ;) */}
      <Form
        name="complex-form"
        ref={formRef}
        initialValues={{ privacy_setting: 'public' }}
        onFinish={onFinish}
        onChange={onFormChange}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 1000 }}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="name"
              label={t('my_printers.printer.name')}
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer.name'),
                  }),
                },
              ]}
              style={{ marginRight: '-35px', marginLeft: '15px' }}
            >
              <Input
                placeholder={t('my_printers.placeholder', {
                  label: t('my_printers.printer.name'),
                })}
              />
            </Form.Item>
            <Form.Item
              name="brand"
              label={t('my_printers.printer.brand')}
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer.brand'),
                  }),
                },
              ]}
              style={{ marginRight: '-35px', marginLeft: '15px' }}
            >
              <Input
                placeholder={t('my_printers.placeholder', {
                  label: t('my_printers.printer.brand'),
                })}
              />
            </Form.Item>
            <Form.Item
              name="manufacturer_number"
              label={t('my_printers.printer.manufacturer_number')}
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer.manufacturer_number'),
                  }),
                },
              ]}
              style={{ marginRight: '-35px', marginLeft: '15px' }}
            >
              <Input
                placeholder={t('my_printers.placeholder', {
                  label: t('my_printers.printer.manufacturer_number'),
                })}
              />
            </Form.Item>

            <Form.Item
              name="verfuegbarkeit"
              label={t('my_printers.printer.availability')}
              style={{ marginRight: '-35px', marginLeft: '15px' }}
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer.availability'),
                  }),
                },
              ]}
            >
              <DateRangePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="speed"
              label={t('my_printers.printer.speed')}
              style={{ marginRight: '-35px', marginLeft: '15px' }}
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer.speed'),
                  }),
                },
              ]}
            >
              <Input
                placeholder={t('my_printers.placeholder', {
                  label: t('my_printers.printer.speed'),
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: 'right', margin: '-20px -10px' }}>
            <img src={PrinterImg} alt="3D printer" />
          </Col>
        </Row>
        <Divider />
        <StyledRow>
          <Col span={6}>
            <div style={{ paddingRight: '25px' }}>
              <h3>{t('my_printers.part_requirements.title')}</h3>
              {t('my_printers.part_requirements.description')}
            </div>
          </Col>
          <Col span={18}>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="min_width"
                  label={t('my_printers.printer.min_width')}
                  rules={[
                    {
                      required: true,
                      message: t('my_printers.is_required', {
                        label: t('my_printers.printer.min_width'),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('max_width') === undefined ||
                          value < getFieldValue('max_width')
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(t('my_printers.printer.min_less_than_max')),
                        )
                      },
                    }),
                  ]}
                >
                  <InputNumber
                    min={0}
                    addonAfter="mm"
                    style={{ justifyContent: 'space-around' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="max_width"
                  label={t('my_printers.printer.max_width')}
                  rules={[
                    {
                      required: true,
                      message: t('my_printers.is_required', {
                        label: t('my_printers.printer.max_width'),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('min_width') === undefined ||
                          value > getFieldValue('min_width')
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            t('my_printers.printer.max_greater_than_min'),
                          ),
                        )
                      },
                    }),
                  ]}
                >
                  <InputNumber min={0} addonAfter="mm" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="min_depth"
                  label={t('my_printers.printer.min_depth')}
                  rules={[
                    {
                      required: true,
                      message: t('my_printers.is_required', {
                        label: t('my_printers.printer.min_depth'),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('max_depth') === undefined ||
                          value < getFieldValue('max_depth')
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(t('my_printers.printer.min_less_than_max')),
                        )
                      },
                    }),
                  ]}
                >
                  <InputNumber min={0} addonAfter="mm" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="max_depth"
                  label={t('my_printers.printer.max_depth')}
                  rules={[
                    {
                      required: true,
                      message: t('my_printers.is_required', {
                        label: t('my_printers.printer.max_depth'),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('min_depth') === undefined ||
                          value > getFieldValue('min_depth')
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            t('my_printers.printer.max_greater_than_min'),
                          ),
                        )
                      },
                    }),
                  ]}
                >
                  <InputNumber min={0} addonAfter="mm" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="min_height"
                  label={t('my_printers.printer.min_height')}
                  rules={[
                    {
                      required: true,
                      message: t('my_printers.is_required', {
                        label: t('my_printers.printer.min_height'),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('max_height') === undefined ||
                          value < getFieldValue('max_height')
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(t('my_printers.printer.min_less_than_max')),
                        )
                      },
                    }),
                  ]}
                >
                  <InputNumber min={0} addonAfter="mm" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="max_height"
                  label={t('my_printers.printer.max_height')}
                  rules={[
                    {
                      required: true,
                      message: t('my_printers.is_required', {
                        label: t('my_printers.printer.max_height'),
                      }),
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          !value ||
                          getFieldValue('min_height') === undefined ||
                          value > getFieldValue('min_height')
                        ) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(
                            t('my_printers.printer.max_greater_than_min'),
                          ),
                        )
                      },
                    }),
                  ]}
                >
                  <InputNumber min={0} addonAfter="mm" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="raumvolumen"
              label={t('my_printers.printer.max_volume')}
            >
              <div style={{ marginLeft: '10px' }}>
                {props.volume === 0
                  ? '-'
                  : `${numberRenderer(t)(props.volume)} l (${t(
                      'my_printers.printer.cubic_decimeter',
                    )})`}
              </div>
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="max_weight"
                  label={t('my_printers.printer.max_weight')}
                  rules={[
                    {
                      required: true,
                      message: t('my_printers.is_required', {
                        label: t('my_printers.printer.max_weight'),
                      }),
                    },
                  ]}
                >
                  <InputNumber min={0} addonAfter="g" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </StyledRow>
        <StyledRow2>
          <Col span={16}>
            <Form.Item
              className="materialsclass"
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer.accepted_materials'),
                  }),
                },
              ]}
              name="materials"
              label={t('my_printers.printer.accepted_materials')}
              style={{ marginLeft: '-15px' }}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder={t('my_printers.select_placeholder')}
                options={materialOptions}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="currentclass"
              name="current_material.name"
              label={t('my_printers.printer.current_material')}
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer.current_material'),
                  }),
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder={t('my_printers.select_placeholder')}
                options={materialOptions}
              />
            </Form.Item>
          </Col>
        </StyledRow2>
        {/* <Row>
          <Col span={3}>
            <div style={{ textAlign: 'right' }}>
              <i>{t('my_printers.feature_in_development')}</i>{' '}
              {t('my_printers.material_mapping.description')}
            </div>
          </Col>
          <Col span={21}>
            <List
              style={{ marginLeft: '23px', marginRight: '22px' }}
              // header={<div>Header</div>}
              // footer={<div>Footer</div>}
              bordered
              dataSource={selectedMaterials}
              renderItem={(item) => (
                <List.Item style={{ display: 'flex' }}>
                  <span>{item}</span>
                  <span>
                    <UilArrowRight />
                  </span>
                  <span>
                    <Form.Item name={item} style={{ margin: '0px' }}>
                      <Input
                        disabled
                        placeholder={t(
                          'my_printers.material_mapping.placeholder',
                        )}
                        style={{ width: '421px' }}
                      />
                    </Form.Item>
                  </span>
                </List.Item>
              )}
            />
          </Col>
        </Row> */}
        <Divider />
        <h3>{t('my_printers.printer_location_form.title')}</h3>
        <br />
        <Row>
          <Col span={24}>
            <Form.Item
              style={{ marginRight: '-58px', marginLeft: '-118px' }}
              name="address.name"
              label={t('my_printers.printer_location_form.company_name')}
              rules={[
                {
                  required: true,
                  message: t('my_printers.is_required', {
                    label: t('my_printers.printer_location_form.company_name'),
                  }),
                },
              ]}
              required
            >
              <Input
                placeholder={t(
                  'my_printers.printer_location_form.company_name',
                )}
              />
            </Form.Item>
            <Form.Item
              label={t('my_printers.printer_location_form.address')}
              style={{
                marginBottom: 0,
                marginRight: '-58px',
                marginLeft: '-123px',
                width: '123.3%',
              }}
              required
            >
              <Form.Item
                name="address.address_line_1"
                rules={[
                  {
                    required: true,
                    message: t('my_printers.is_required', {
                      label: t('my_printers.printer_location_form.address'),
                    }),
                  },
                ]}
                style={{ display: 'inline-block', width: 'calc(50% - 8px)' }}
              >
                <Input placeholder={t('address.line_1_placeholder')} />
              </Form.Item>
              <Form.Item
                name="address.address_line_2"
                style={{
                  display: 'inline-block',
                  width: 'calc(50% - 8px)',
                  margin: '0 8px',
                }}
              >
                <Input placeholder={t('address.line_2_placeholder')} />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label={t('address.postcode_city')}
              style={{
                marginBottom: 0,
                marginRight: '-58px',
                marginLeft: '-123px',
                width: '124%',
              }}
              required
            >
              <Form.Item
                name="address.postcode"
                rules={[
                  {
                    required: true,
                    message: t('my_printers.is_required', {
                      label: t('address.postcode'),
                    }),
                  },
                ]}
                style={{ display: 'inline-block', width: 'calc(16.6% - 8px)' }}
              >
                <InputNumber
                  placeholder={t('address.postcode')}
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item
                name="address.city"
                rules={[
                  {
                    required: true,
                    message: t('my_printers.is_required', {
                      label: t('address.city'),
                    }),
                  },
                ]}
                style={{
                  display: 'inline-block',
                  width: 'calc(33% - 8px)',
                  margin: '0 8px',
                }}
              >
                <Input placeholder={t('address.city')} />
              </Form.Item>
              <Form.Item
                name="address.state"
                rules={[
                  {
                    required: true,
                    message: t('my_printers.is_required', {
                      label: t('address.state_placeholder'),
                    }),
                  },
                ]}
                style={{
                  display: 'inline-block',
                  width: 'calc(33% - 8px)',
                  margin: '0 8px 0 0',
                }}
              >
                <Input placeholder={t('address.state_placeholder')} />
              </Form.Item>
              <Form.Item
                name="address.country"
                initialValue="DE"
                rules={[
                  {
                    required: true,
                    message: t('my_printers.is_required', {
                      label: t('address.country'),
                    }),
                  },
                ]}
                style={{
                  display: 'inline-block',
                  width: 'calc(16.6% - 8px)',
                  margin: '0px',
                }}
              >
                <Select
                  variant="outlined"
                  options={[
                    { value: 'DE', label: 'DE' },
                    { value: 'FR', label: 'FR' },
                    { value: 'US', label: 'US' },
                    { value: 'CN', label: 'CN', disabled: true },
                  ]}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Form.Item
          style={{ marginRight: '-58px', marginLeft: '-118px' }}
          name="privacy_setting"
          label={t('my_printers.printer_availability.title')}
          required
        >
          <Segmented
            block
            options={[
              {
                label: t('my_printers.printer_availability.public'),
                value: 'public',
              },
              {
                label: t('my_printers.printer_availability.private'),
                value: 'private',
              },
              {
                label: t('my_printers.printer_availability.inactive'),
                value: 'inactive',
              },
            ]}
            value={active}
            onChange={setActive}
          />
        </Form.Item>
        <Divider />
        <Form.Item
          label=" "
          colon={false}
          style={{ textAlign: 'right', marginRight: '-44px' }}
        >
          {props.newPrinter ? (
            <Button onClick={onCancel} style={{ marginRight: '10px' }}>
              {t('button.cancel')}
            </Button>
          ) : (
            <Popconfirm
              placement="top"
              title="Delete Printer"
              description="Are you sure to delete this printer?"
              onConfirm={onDelete}
              okText="Yes"
              cancelText="No"
            >
              <Button style={{ marginRight: '10px' }}>
                {t('button.delete')}
              </Button>
            </Popconfirm>
          )}
          <Button onClick={onReset} style={{ marginRight: '10px' }}>
            {t('button.discard')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('button.submit')}
          </Button>
        </Form.Item>
      </Form>
    </StyledCard>
  )
}

export default Printerform
