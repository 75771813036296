import React, {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react'
import {
  GroupedDiv,
  StyledDiv,
  StyledTable,
  StyledTableDWHM,
  StyledSubTableDWHM,
} from './styledComponents'
import { TableRowSelection } from 'antd/lib/table/interface'
import type { ColumnsType } from 'antd/es/table'
import type { TableColumnsType } from 'antd'
import DWHMaterials from './DWHMaterials'
import DWHMaterials2 from './DWHMaterials_alternative'
import ClientMaterials from './ClientMaterials'
import MaterialInfo from './MaterialInfo'
import { Button, Result, Table, App } from 'antd'
import createColumnsCM from './createColumnsCM'
import createColumnsDWHM from './createColumnsDWHM'

const Materialmapper = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedDWHMaterialKeys, setSelectedDWHMaterialKeys] = useState<
    React.Key[]
  >([])
  const { message, notification, modal } = App.useApp()

  const openNotification = (msg) => {
    notification.success({
      message: 'Success',
      description: msg,
      onClick: () => {},
      placement: 'top',
    })
  }
  const onSubmit = () => {
    openNotification('work in progress...')
  }

  return (
    <>
      <StyledDiv>
        <div style={{ width: '32%' }}>
          <h3>Map your Materials</h3>
          <br />
          <ClientMaterials
            setSelectedRowKeys={setSelectedRowKeys}
            selectedRowKeys={selectedRowKeys}
          />
          {/* <p>Selected: {selectedRow ? selectedRow.name : "None"}</p> */}
        </div>
        <GroupedDiv>
          <div style={{ width: '47%' }}>
            <h3>DWH Materials</h3>
            <p>
              Please choose one or more Materials to map your selected
              Part-Material
            </p>
            <br />
            <DWHMaterials2
              selectedRowKeys={selectedRowKeys}
              setSelectedDWHMaterialKeys={setSelectedDWHMaterialKeys}
            />
            {/* <DWHMaterials selectedRowKeys={selectedRowKeys}/> */}
            <Button
              onClick={onSubmit}
              type="primary"
              style={{ margin: '20px 0 7px', float: 'right' }}
            >
              Map Material
            </Button>
          </div>
          <div style={{ width: '47%' }}>
            <MaterialInfo selectedDWHMaterialKeys={selectedDWHMaterialKeys} />
          </div>
        </GroupedDiv>
      </StyledDiv>
    </>
  )
}

export default Materialmapper
