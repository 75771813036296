import { PartNoBg } from '$pages/serviceuser/Marketplace/Bookservice/Defaultwizard/styledComponents'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { StyledTable } from './styledComponents'
import { useTranslation } from 'react-i18next'
import BookedServiceLabels from '$components/Label/BookedServiceLabels'
import PartFileDownloadButton from '$components/FileDownload/PartFileDownloadButton'
import { calculateOrderPartPrice } from '$components/OrderPartsListTable/utils'
import { currencyRenderer } from '$utils/i18n'
import { ValidationWrapper } from '$globalStyledComponents/Text'
import useMaterialMapping from '$utils/useMaterialMapping'

const RequestedPartsTable = ({
  orderParts = [],
  loading,
  next,
  currentStep,
  orderPartsFormValues,
}) => {
  const { t } = useTranslation()

  const { data: materialsById, isLoading: materialsAreLoading } =
    useMaterialMapping()

  const columns = [
    {
      title: t('Part No'),
      dataIndex: ['part', 'id_part_client'],
      render: (title) => <PartNoBg>{title}</PartNoBg>,
    },
    {
      title: t('Part Name'),
      dataIndex: ['part', 'name'],
    },
    {
      title: t('Material'),
      dataIndex: 'material',
      render: (materialId) =>
        !materialsAreLoading && materialsById[materialId]?.name,
    },
    {
      title: t('Quantity'),
      dataIndex: 'amount',
    },
    {
      title: t('Dimensions'),
      render: (record) => (
        <div>
          {
            record.part.float_attributes.find(
              (attribute) => attribute.key === 'Width',
            )?.value
          }{' '}
          x{' '}
          {
            record.part.float_attributes.find(
              (attribute) => attribute.key === 'Height',
            )?.value
          }{' '}
          x{' '}
          {
            record.part.float_attributes.find(
              (attribute) => attribute.key === 'Depth',
            )?.value
          }
        </div>
      ),
    },
    {
      title: t('received_requests.offered_price'),
      render: (record) => {
        const editableOfferValues =
          orderPartsFormValues?.[record.id]?.['provider_offers'] &&
          Object.values(orderPartsFormValues?.[record.id]?.['provider_offers'])

        let price
        if (editableOfferValues?.length) {
          price = calculateOrderPartPrice(
            editableOfferValues.filter(
              (offer) => offer.amount && offer.base_price,
            ),
          )
        } else if (record.provider_offers?.length) {
          price = calculateOrderPartPrice(
            record.provider_offers.filter(
              (offer) => offer.amount && offer.base_price,
            ),
          )
        }

        if (price) {
          return (
            <ValidationWrapper valid={price > 0}>
              {currencyRenderer(t)(price)}
            </ValidationWrapper>
          )
        } else {
          return t('received_requests.not_yet_defined')
        }
      },
    },
    {
      title: 'Labels',
      render: (record) => (
        <div style={{ width: '170px' }}>
          <BookedServiceLabels bookedServiceId={record.id} />
        </div>
      ),
    },
    {
      title: '',
      fixed: 'right',
      render: (record) => {
        const handleClick = () => {
          next(record.id)
        }
        const hasProviderOffers =
          record.provider_offers?.length && record.provider_offers.length > 0

        return currentStep === 0 ? (
          hasProviderOffers ? (
            <div style={{ textAlign: 'right' }}>
              <Button shape="round" size="small" onClick={handleClick}>
                {t('received_requests.edit_offer')} <ArrowRightOutlined />
              </Button>
            </div>
          ) : (
            <div style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                shape="round"
                size="small"
                onClick={handleClick}
              >
                {t('received_requests.edit_request')} <ArrowRightOutlined />
              </Button>
            </div>
          )
        ) : (
          <PartFileDownloadButton
            shape="round"
            size="small"
            fileName={record.user_file_location}
          >
            {t('received_requests.download_stl_file')}
          </PartFileDownloadButton>
        )
      },
    },
  ]

  return (
    <StyledTable
      size="small"
      columns={columns}
      style={{ width: '100%' }}
      dataSource={orderParts}
      loading={loading}
      pagination={currentStep === 0 ? true : false}
      rowKey={(data) => data.id}
    />
  )
}

export default RequestedPartsTable
