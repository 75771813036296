import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { TableRowSelection } from 'antd/lib/table/interface'
import { useTranslation } from 'react-i18next'
import createColumnsConfig from './createColumnsConfig'
import { StyledTable } from './styledComponents'

interface DataType {
  key: string
  name: string
  standort: string
}
interface Props {
  setNewPrinter: Dispatch<SetStateAction<boolean>>
  setPrinterID: Dispatch<SetStateAction<string>>
  setPrinterFormData: Dispatch<SetStateAction<any[]>>
  newPrinter: boolean
  printerFormData: any
  printerID: string
  setFormHasTouched: Dispatch<SetStateAction<boolean>>
  formHasTouched: boolean
}

const tData = (data) =>
  Array.isArray(data) && data?.map((el, i) => ({ key: i, ...el }))

const Myprinterlist = (props: Props) => {
  const { t } = useTranslation()

  const [data, setData] = useState<DataType[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [count, setCount] = useState<number>(1)

  useEffect(() => {
    if (props.printerFormData.items) {
      setData(
        props.printerFormData.items.map((obj) => ({
          key: obj.id,
          name: obj.name,
          standort: obj.address.city,
        })),
      )
    }
  }, [props.printerFormData])

  const onRow = (record: DataType) => {
    return {
      onClick: () => {
        props.setPrinterID(record.key)
        setSelectedRowKeys([record.key])
        props.setFormHasTouched(false)
      },
    }
  }
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const addPrinter = () => {
    const newData: DataType = {
      key: String(count),
      name: `${t('my_printers.new_printer_default_name')} ${count}`,
      standort: '-',
    }
    props.setNewPrinter(true)
    setData([...data, newData])
    setSelectedRowKeys([String(count)] as any)
    setCount(count + 1)
  }

  useEffect(() => {
    if (data.length > 1 && count === 1) {
      setSelectedRowKeys(data[data.length - 1]?.key as any)
    }
  }, [data, count])

  useEffect(() => {
    if (props.printerID === '') {
      setSelectedRowKeys([data[0]?.key] as any)
    }
  }, [props.printerID, data])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>{t('my_printers.title')}</h3>
        <Button type="primary" onClick={addPrinter} disabled={props.newPrinter}>
          <PlusOutlined />
        </Button>
      </div>
      <br />
      <StyledTable
        columns={createColumnsConfig(t)}
        dataSource={tData(data)}
        rowSelection={props.newPrinter ? null : rowSelection}
        onRow={onRow}
        rowKey={(record) => record.key}
      />
      {/* <p>Selected: {selectedRow ? selectedRow.name : "None"}</p> */}
    </>
  )
}

export default Myprinterlist
