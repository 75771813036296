import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Col, Row, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { evoConsolidation } from '$api'
import { fetchAllMaterials, fetchAllPrinters } from '$api/evoAPIs'
import Footer from '$components/Footer'
import { StyledCard } from '$globalStyledComponents/Card'
import { StyledLayout } from '$globalStyledComponents/Layout'
import Myprinterlist from './Myprinterlist'
import Printerform from './Printerform'

const { Content } = StyledLayout

const Myprinters = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'my_printers' })

  const [newPrinter, setNewPrinter] = useState(false)
  const [forceRerender, setForceRerender] = useState(false)
  const [formHasTouched, setFormHasTouched] = useState(false)
  const [printerFormData, setPrinterFormData] = useState<string[]>([])
  const [printerID, setPrinterID] = useState<string>('')
  const [volume, setVolume] = useState<number>(0)
  // const { message, notification, modal } = App.useApp()
  const queryClient = useQueryClient()

  const { data: printer } = useQuery(['printer'], fetchAllPrinters, {
    enabled: !newPrinter && !formHasTouched,
    onSuccess: (response: any) => {
      if (!printerID) {
        if (response.items.length) {
          setPrinterID(response.items[0].id)
        } else {
          setNewPrinter(true)
        }
      }
      setForceRerender(!forceRerender)
    },
    onError: (e: any) => {
      console.error(e)
      openErrorNotification(
        e?.response?.status ? String(e?.response?.status) : String(e?.message),
      )
    },
  })

  const { data: materials } = useQuery(['materials'], fetchAllMaterials, {
    onError: (e: any) => {
      console.error(e)
    },
  })

  const updatePrinterMutation = useMutation(
    (values: any) => {
      values.printer_status = 'enabled'
      values.address.postcode = values.address.postcode.toString()
      return evoConsolidation.put(`/printers/${printerID}/`, values, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        openSuccessNotification(t('printer_updated'))
        queryClient.invalidateQueries(['printer'])
      },
      onError: (e) => {
        console.error(e)
      },
    },
  )
  const postNewPrinterMutation = useMutation(
    (values: any) => {
      values.address.postcode = values.address.postcode.toString()
      return evoConsolidation.post('/printers/', JSON.stringify(values), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        openSuccessNotification(t('new_printer_created'))
        queryClient.invalidateQueries(['printer'])
        setNewPrinter(false)
      },
      onError: (e) => {
        console.error(e)
      },
    },
  )
  const deletePrinterMutation = useMutation(
    async () => {
      return evoConsolidation.delete(`/printers/${printerID}/`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        openSuccessNotification(t('new_printer_deleted'))
        setPrinterID('')
        queryClient.invalidateQueries(['printer'])
      },
      onError: (e: any) => {
        console.error(e)
        if (e.response.status === 403) {
          openErrorNotification(
            t('printer_could_not_be_deleted.insufficient_rights'),
          )
        } else {
          openErrorNotification(
            t('printer_could_not_be_deleted.insufficient_rights.default'),
          )
        }
      },
    },
  )

  useEffect(() => {
    if (newPrinter) {
      setPrinterFormData([])
      setVolume(0)
    }
  }, [newPrinter])

  useEffect(() => {
    if (materials && printer) {
      dayjs.extend(utc)
      const transformData = (inputData, outputData) => {
        return inputData.map((item) => {
          const matchedMaterial1 = item.materials.map((materialId) => {
            const foundMaterial = outputData.find(
              (outputItem) => outputItem.id === materialId, // Direct comparison to string ID
            )
            return foundMaterial ? foundMaterial.name : null
          })
          const foundMaterial = outputData.find(
            (outputItem) => outputItem.id === item.current_material, // Direct comparison to string ID
          )
          return {
            ...item,
            materials: matchedMaterial1.filter(Boolean),
            current_material: foundMaterial ? foundMaterial.name : null, // Assuming you want the name here
            // For verfuegbarkeit, make sure dayjs is imported and available_from/to are part of your data structure
            verfuegbarkeit: [
              dayjs(item.available_from, 'YYYY/MM/DD'),
              dayjs(item.available_to, 'YYYY/MM/DD'),
            ],
          }
        })
      }
      const transformedData = { items: transformData(printer.items, materials) }
      setPrinterFormData(transformedData as any)
    }
  }, [materials, printer])

  const openSuccessNotification = (msg) => {
    notification.success({
      message: 'Success',
      description: msg,
      onClick: () => {},
      placement: 'top',
    })
  }
  const openErrorNotification = (msg) => {
    notification.error({
      message: 'Error',
      description: msg,
      onClick: () => {},
      placement: 'top',
    })
  }
  const onFinish = (values: any) => {
    const transformForEndpoints = (inputData, outputData) => {
      const matchedMaterial = inputData.materials.map((materialName) => {
        const foundMaterial = outputData.find(
          (outputItem) => outputItem.name === materialName,
        )
        return foundMaterial ? foundMaterial.id : null
      })
      const foundMaterial = outputData.find(
        (outputItem) => outputItem.name === inputData.current_material.name,
      )
      return {
        materials: matchedMaterial,
        current_material: foundMaterial.id && foundMaterial.id,
      }
    }
    const reversedData = transformForEndpoints(values, materials)
    values.materials = reversedData.materials
    values.current_material = reversedData.current_material
    values.available_from = values.verfuegbarkeit[0].utc().format()
    values.available_to = values.verfuegbarkeit[1].utc().format()
    if (newPrinter) {
      postNewPrinterMutation.mutate(values)
    } else {
      updatePrinterMutation.mutate(values)
    }
  }
  const onDelete = (printerid) => {
    deletePrinterMutation.mutate(printerid)
  }

  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '50px 0' }}>{t('title')}</h1>
        <Row>
          <Col span="8">
            <StyledCard>
              <Myprinterlist
                newPrinter={newPrinter}
                setNewPrinter={setNewPrinter}
                printerFormData={printerFormData}
                setPrinterID={setPrinterID}
                setPrinterFormData={setPrinterFormData}
                printerID={printerID}
                setFormHasTouched={setFormHasTouched}
                formHasTouched={formHasTouched}
              />
            </StyledCard>
          </Col>
          <Col span="16">
            <div>
              <Printerform
                newPrinter={newPrinter}
                setNewPrinter={setNewPrinter}
                printerFormData={printerFormData}
                setPrinterFormData={setPrinterFormData}
                printerID={printerID}
                //printer={printer}
                setPrinterID={setPrinterID}
                forceRerender={forceRerender}
                onFinish={onFinish}
                onDelete={onDelete}
                materials={materials}
                setFormHasTouched={setFormHasTouched}
                formHasTouched={formHasTouched}
                volume={volume}
                setVolume={setVolume}
              />
            </div>
          </Col>
        </Row>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default Myprinters
