import { StyledLayout } from '$globalStyledComponents/Layout'
import { StyledButton } from '$globalStyledComponents/Buttons'
import Footer from '$components/Footer'
import { useTranslation } from 'react-i18next'
import Topwelcomecard from './Topwelcomecard'
//import Editgroup from './Editgroup'
import Tabcontent from './Tabcontent'
import { Row, Col, Button, Popconfirm, Modal, message } from 'antd'
import { StyledGroupTabs } from './styledComponents'
import { useRef, useState, useEffect } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { fetchAllGroups, postGroup, putGroup, deleteGroup } from '$api/client'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { FcUpdateParts } from '$utils/FcUpdateParts'
import { useRefetchTimer } from '$context/RefetchTimerProvider'

import AllPartsTable from './AllPartsTable'

const { Content } = StyledLayout

const COLUMNS = [
  'feasible',
  'part_id',
  'name',
  'width',
  'depth',
  'height',
  'weight',
  'material',
  'price',
  'price_am',
  'created_at',
  'updated_at',
]

const Partsmasterdata = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { startRefetch } = useRefetchTimer()
  const [partsCount, setPartsCount] = useState(0)

  function searchGroupName(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].key === nameKey) {
        return i
      }
    }
  }

  const defaultPanes = [
    {
      label: t('All Parts'),
      children: (
        <AllPartsTable
          columns={COLUMNS}
          setPartsTotal={(total) => setPartsCount(total)}
        />
      ),
      key: '1',
      disabled: false,
    },
  ]

  const [groupState, setGroupState] = useState({
    tabId: 1,
    isNotSaved: false,
    partsCount: undefined,
  })
  let groupArr = [groupState]
  const [activeKey, setActiveKey] = useState(defaultPanes[0].key)
  const [items, setItems] = useState(defaultPanes)
  const [groupPartsLength, setGroupPartsLength] = useState(0)
  const newTabIndex = useRef(0)
  const [groupName, setGroupName] = useState('')
  const [groupDescription, setGroupDescription] = useState('')
  const [groupFilterDescription, setGroupFilterDescription] = useState('')
  const [_listPartIDs, setListPartIDs] = useState([])
  const [addNewGroup, setAddNewGroup] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const [isChangedPlusButton, setIsChangedPlusButton] = useState(false)

  //react queries
  const { data: groupsData } = useQuery(['allgroups'], fetchAllGroups)
  const newGroupMutation = useMutation(postGroup, {
    onSuccess: () => {
      setAddNewGroup(false)
      message.success(t('parts_master_data.group_created'))
      queryClient.invalidateQueries(['allgroups'])
    },
  })
  const updateGroupMutation = useMutation(putGroup, {
    onSuccess: () => {
      setAddNewGroup(false)
      message.success(t('parts_master_data.group_updated'))
      queryClient.invalidateQueries(['allgroups'])
    },
  })
  const deleteGroupMutation = useMutation(deleteGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(['allgroups'])
    },
    onError: (e) => {
      console.error(e)
      queryClient.resetQueries(['allgroups'])
    },
  })

  const onChange = (key) => {
    setActiveKey(key)
  }

  useEffect(() => {
    if (activeKey !== '1') {
      if (
        groupsData[groupsData.map((e) => e.id).indexOf(activeKey)]?.total_parts
      ) {
        setGroupPartsLength(
          groupsData[groupsData.map((e) => e.id).indexOf(activeKey)]
            ?.total_parts,
        )
      } else if (activeKey.includes('T')) {
        let grArr = groupState
        grArr[groupState.map((e) => e.tabId).indexOf(activeKey)].partsCount =
          groupPartsLength
        setGroupState(grArr)
      }
    } else {
      setGroupPartsLength('-')
    }
  }, [activeKey]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeKey.includes('T')) {
      let grArr = groupState
      grArr[groupState.map((e) => e.tabId).indexOf(activeKey)].partsCount =
        groupPartsLength
      setGroupState(grArr)
    }
  }, [groupPartsLength]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeKey !== items[0].key) {
      const newPanes2 = [...items]
      if (isChanged) {
        newPanes2[searchGroupName(activeKey, items)].label = groupName
      }
      setItems(newPanes2)
    }
  }, [groupName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isChanged) {
      for (let e in groupState) {
        items[e].disabled = true
      }
      setIsChangedPlusButton(true)
    }
  }, [isChanged]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // do some checking here to ensure data exist
    if (groupsData) {
      const newPanes = new Array(groupsData.length)
        .fill(null)
        .map((_, index) => {
          const id = groupsData[index].id
          setGroupName(groupsData[index].list_name)
          groupArr.push({
            tabId: id,
            isNotSaved: false,
            partsCount: groupsData[index].total_parts,
          })
          setGroupPartsLength(groupsData[index].total_parts)
          setGroupFilterDescription(groupsData[index].filter_description)
          setActiveKey(id)
          return {
            label: groupsData[index].list_name,
            children: (
              <Tabcontent
                groupName={groupsData[index].list_name}
                setGroupName={setGroupName}
                groupDescription={groupsData[index].description}
                setGroupDescription={setGroupDescription}
                filterDescription={groupsData[index].filter_description}
                setGroupFilterDescription={setGroupFilterDescription}
                setPartsCount={setPartsCount}
                setIsChanged={setIsChanged}
                setListPartIDs={setListPartIDs}
                setGroupPartsLength={setGroupPartsLength}
                addNewGroup={false}
                groupId={groupsData[index].id}
                activeKey={activeKey}
                columns={COLUMNS}
              />
            ),
            key: id,
            disabled: false,
          }
        })
      newPanes.unshift(defaultPanes[0])
      setItems(newPanes)
      setGroupState(groupArr)
    }
    queryClient.removeQueries(['groupparts', null])
  }, [groupsData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (localStorage.getItem('notupdatedfc')) {
      startRefetch(
        1000,
        () => {
          queryClient.refetchQueries(['feasibilitycheck'])
        },
        0,
      )
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = () => {
    if (!groupName) {
      info()
      return
    }
    for (let e in groupState) {
      items[e].disabled = false
    }
    setIsChangedPlusButton(false)

    // set groupName when not touching "editparts" only doing things in "masterdatatable"
    let body = {
      list_name: groupName,
      description: groupDescription,
      filter_description: groupFilterDescription,
    }
    if (addNewGroup) {
      newGroupMutation.mutate(body)
    } else {
      updateGroupMutation.mutate({
        ...body,
        groupId: activeKey,
      })
    }
  }

  const onDelete = (targetKey) => {
    remove(targetKey)
    setIsChangedPlusButton(false)
    if (!addNewGroup) {
      deleteGroupMutation.mutate(activeKey)
    } else {
      queryClient.resetQueries(['allgroups'])
    }
    setActiveKey(defaultPanes[0].key)
  }

  const add = () => {
    setIsChangedPlusButton(true)
    setAddNewGroup(true)
    setGroupDescription('')
    const newActiveKey = `T${newTabIndex.current++}`
    setGroupFilterDescription('')
    setGroupName(`${t('parts_master_data.new_group')} ${newTabIndex.current}`)
    //Disable all other tabs
    for (let e in groupState) {
      items[e].disabled = true
    }

    groupState.push({ tabId: newActiveKey, isNotSaved: true, partsCount: 0 })
    setItems([
      ...items,
      {
        label: (
          <>
            <span>{`${t('parts_master_data.new_group')} ${
              newTabIndex.current
            }`}</span>
            <span></span>
          </>
        ),
        children: (
          <Tabcontent
            groupName={`${t('parts_master_data.new_group')} ${
              newTabIndex.current
            }`}
            setGroupName={setGroupName}
            groupDescription={''}
            setGroupDescription={setGroupDescription}
            filterDescription={''}
            setGroupFilterDescription={setGroupFilterDescription}
            setPartsCount={setPartsCount}
            setIsChanged={setIsChanged}
            setListPartIDs={setListPartIDs}
            setGroupPartsLength={setGroupPartsLength}
            addNewGroup={false}
            groupId={undefined}
            activeKey={activeKey}
          />
        ),
        key: newActiveKey,
      },
    ])
    setActiveKey(newActiveKey)
    setIsChanged(true)
  }
  const remove = (targetKey) => {
    const targetIndex = items.findIndex((pane) => pane.key === targetKey)
    const newPanes = items.filter((pane) => pane.key !== targetKey)
    if (newPanes.length && targetKey === activeKey) {
      const { key } =
        newPanes[
          targetIndex === newPanes.length ? targetIndex - 1 : targetIndex
        ]
      setActiveKey(key)
    }
    setItems(newPanes)
  }
  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add()
    } else {
      remove(targetKey)
    }
  }
  const info = () => {
    Modal.info({
      title: t('parts_master_data.delete_group_confirm'),
      content: (
        <div>
          <p>{t('parts_master_data.name_required')}</p>
        </div>
      ),
      onOk() {},
    })
  }

  return (
    <StyledLayout className="layout">
      <Content>
        <Row style={{ margin: '46px 0' }}>
          <Col flex={5} style={{ margin: 'auto' }}>
            <h1 style={{ margin: '0px 0' }}>{t('parts_master_data.title')}</h1>
          </Col>
          <Col style={{ marginRight: '25px' }} flex={1}></Col>
        </Row>
        <Topwelcomecard
          groupsCount={items.length - 1}
          isGroup={activeKey !== '1'}
          currentGroupPartsCount={groupPartsLength}
          partsCount={partsCount}
        />
        <div className="partsmasterdatatablecard">
          <StyledGroupTabs
            animated
            hideAdd
            tabBarExtraContent={{
              left: (
                <StyledButton onClick={add} disabled={isChangedPlusButton}>
                  <PlusOutlined />
                </StyledButton>
              ),
            }}
            onChange={onChange}
            activeKey={activeKey}
            type="card"
            onEdit={onEdit}
            items={items}
          />
          {addNewGroup ? (
            <div style={{ textAlign: 'right' }}>
              <Button
                type="secondary"
                style={{ margin: '15px' }}
                onClick={() => {
                  onDelete()
                  setAddNewGroup(false)
                }}
              >
                {t('button.cancel')}
              </Button>
              <Button
                type="primary"
                style={{ margin: '15px' }}
                onClick={onSave}
                disabled={groupName === ''}
              >
                {t('parts_master_data.add_group')}
              </Button>
            </div>
          ) : activeKey !== '1' ? (
            <div style={{ textAlign: 'right' }}>
              <Popconfirm
                title={t('parts_master_data.delete_group_confirm')}
                okText={t('Yes')}
                cancelText={t('No')}
                onConfirm={onDelete}
              >
                <Button type="secondary" style={{ margin: '15px 0' }}>
                  {t('parts_master_data.delete_group')}
                </Button>
              </Popconfirm>
              <Button
                type="primary"
                style={{ margin: '15px' }}
                onClick={onSave}
                disabled={!isChanged}
              >
                {t('parts_master_data.save_group')}
              </Button>
            </div>
          ) : (
            ''
          )}
        </div>
        <FcUpdateParts />
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default Partsmasterdata
