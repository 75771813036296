import { Button } from 'antd'

const PrivacyEN = () => {
  const openPrivacySettings = () => {
    if (window.UC_UI && window.UC_UI.showSecondLayer) {
      window.UC_UI.showSecondLayer()
    } else {
      console.error('UserCentrics UI is not available.')
    }
  }
  return (
    <div style={{ margin: '0 200px' }}>
      <h1>Privacy Statement</h1>
      <p>
        <strong>
          The controller as per the EU General Data Protection Regulation (GDPR)
          is:
        </strong>
      </p>
      <p>
        Daimler Buses GmbH ("We")
        <br />
        Fasanenweg 10
        <br />
        70771 Leinfelden-Echterdingen
        <br />
        Germany
        <br />
        Email:{' '}
        <a href="mailto:dataprivacy-bus@daimlertruck.com">
          dataprivacy-bus@daimlertruck.com
        </a>
      </p>

      <h2>Data Privacy Officer:</h2>
      <p>
        Daimler Truck AG
        <br />
        Chief Data Privacy Officer
        <br />
        HPC DTF2B
        <br />
        70745 Leinfelden-Echterdingen
        <br />
        Germany
        <br />
        Email:{' '}
        <a href="mailto:dataprivacy@daimlertruck.com">
          dataprivacy@daimlertruck.com
        </a>
      </p>

      <h2>1. Data Protection</h2>
      <p>
        We appreciate you visiting our website and your interest in the products
        we offer. Protecting your personal data is very important to us. In this
        Privacy Statement, we explain how we collect your personal information,
        what we do with it, for what purposes and on what legal basis this is
        done, and what rights you have. In addition, we refer to Daimler Truck's
        Data Protection Policy:
      </p>
      <p>
        <a href="https://www.daimlertruck.com/fileadmin/user_upload/dokumente/unternehmen/datenschutz/data-protection-policy-eu.pdf">
          Daimler Truck Data Protection Policy
        </a>
        .
      </p>
      <p>
        Our Privacy Statement on the use of our websites and the Daimler Truck
        Data Protection Policy do not apply to your activities on the websites
        of social networks or other providers that can be accessed using the
        links on our websites. Please read the data protection provisions on the
        websites of those providers.
      </p>

      <h2>2. Collecting and processing your personal data</h2>
      <p>
        <strong>a.</strong> Whenever you visit our websites, we store certain
        information about the browser and operating system you are using; the
        date and time of your visit; the status of the interaction (e.g. whether
        you were able to access the website or received an error message); the
        usage of features on the website; any search phrases you entered; how
        often you visit individual websites; the names of the files you access;
        the amount of data transferred; the webpage from which you accessed our
        website; and the webpage you visited after visiting our website, whether
        by clicking links on our websites or entering a domain directly into the
        input field of the same tab (or window) of the browser in which you have
        our websites open. In addition, we store your IP address and the name of
        your Internet service provider for seven days. This is for security
        reasons; in particular, to prevent and detect attacks on our websites or
        attempts at fraud.
      </p>
      <p>
        <strong>b.</strong> We only store other personal data if you provide
        this data, e.g., as part of a registration, contact form, chat, survey,
        price competition or for the execution of a contract, and even in these
        cases only insofar as this is permitted to us on the basis of a consent
        given by you or in accordance with the applicable legal provisions (see
        section 7).
      </p>
      <p>
        <strong>c.</strong> You are neither legally nor contractually obligated
        to share your personal information. However, certain features of our
        websites may depend on the sharing of personal information. If you do
        not provide your personal information in such cases, you may not be able
        to use those features, or they may be available with limited
        functionality.
      </p>

      <h2>3. Purpose of use</h2>
      <p>
        <strong>a.</strong> We use the personal information collected during
        your visit to any of our websites to make using them as convenient as
        possible for you and to protect our IT systems against attacks and other
        unlawful activities.
      </p>
      <p>
        <strong>b.</strong> If you share additional information with us – for
        example, by filling out a registration form, contact form, chat, survey,
        competition or to execute a contract– we will use that data for the
        mentioned purposes, purposes of customer management and – if required –
        for purposes of processing and billing any business transactions to the
        extent required in each instance.
      </p>
      <p>
        <strong>c.</strong> For other purposes (e.g., display of personalized
        content or advertising based on your usage behaviour), we and, if
        applicable, selected third parties, use your personal data if and to the
        extent you give your consent through our consent management system. You
        will find further information and decision-making options here:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Open privacy settings
        </Button>
      </p>
      <p>
        <strong>d.</strong> In addition, we use personal data to the extent that
        we are legally obliged to do so (e.g., storage for the fulfilment of
        commercial or tax-related retention obligations, release in accordance
        with official or judicial orders, e.g., to a law enforcement authority).
      </p>

      <h2>
        4. Transfer of Personal Data to Third Parties; Social Plugins; Use of
        Service Providers
      </h2>
      <p>
        <strong>a.</strong> Our websites may also contain an offer of third
        parties. If you click on such an offer, we transfer data to the
        respective provider to the required extent (e.g., information that you
        have found this offer with us and, if applicable, further information
        that you have already provided on our websites for this purpose).
      </p>
      <p>
        <strong>b.</strong> When we use social plug-ins on our websites from
        social networks such as Facebook and Twitter, we integrate them as
        follows: When you visit our websites, the social plug-ins are
        deactivated, i.e., no data is transmitted to the operators of these
        networks. If you want to use one of the networks, click on the
        respective social plug-in to establish a direct connection to the server
        of the respective network. If you have a user account with the network
        and are logged in when you activate the social plug-in, the network can
        associate your visit to our websites with your user account. If you want
        to avoid this, please log out of the network before activating the
        social plug-in. A social network cannot associate a visit to other
        Daimler Truck websites until you have activated an existing social
        plug-in. When you activate a social plug-in, the network transfers the
        resulting content directly to your browser, which integrates it into our
        websites. In this situation, data transmissions can also take place that
        are initiated and controlled by the respective social network. Your
        connection to a social network, the data transfers taking place between
        the network and your system, and your interactions on that platform are
        governed solely by the privacy policies of that network. The social
        plug-in remains active until you deactivate it or delete your website
        technologies (see section 5.d).
      </p>
      <p>
        <strong>c.</strong> If you click on the link to an offer or activate a
        social plug-in, personal data may reach providers in countries outside
        the European Economic Area that, from the point of view of the European
        Union ("EU"), may not guarantee an "adequate level of protection" for
        the processing of personal data in accordance with EU standards. Please
        keep this in mind before clicking on a link or activating a social
        plug-in and thereby triggering a transfer of your data.
      </p>
      <p>
        <strong>d.</strong> We also use qualified service providers (e.g., IT
        service providers, marketing agencies) to operate, optimize and secure
        our websites. We only transfer personal data to the latter insofar as
        this is necessary for the provision and use of the website and its
        functionalities, for the pursuit of legitimate interests, to comply with
        legal obligations, or insofar as you have consented to this (see section
        7). You will find more information regarding recipients of personal data
        in our consent management system:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Open privacy settings
        </Button>
      </p>

      <h2>5. Website technologies</h2>
      <p>
        <strong>a.</strong> Website technologies may be used when you are
        visiting our websites. Technically, these are so-called HTML cookies and
        similar software tools such as Web/DOM Storage or Local Shared Objects
        (so-called "Flash cookies"), which we collectively refer to as website
        technologies.
      </p>
      <p>
        <strong>b.</strong> Website technologies are small files that are stored
        on your desktop, notebook or mobile device while you visit a website.
        Website technologies make it possible, for example, to determine whether
        there has already been a connection between the device and the websites,
        to take into account your preferred language or other settings, to offer
        you certain functions (e.g. online shop, vehicle configurator) or to
        recognize your usage-based interests. Website technologies may also
        contain personal data.
      </p>
      <p>
        <strong>c.</strong> Whether and which website technologies are used when
        you visit our websites depends on which areas and functions of our
        websites you use and whether you agree to the use of website
        technologies that are not technically required in our Consent Management
        System. You will find further information and decision-making options
        here:{' '}
        <Button
          type="link"
          size="small"
          onClick={openPrivacySettings}
          style={{ padding: '0', height: 'fit-content' }}
        >
          Open privacy settings
        </Button>
      </p>
      <p>
        <strong>d.</strong> The use of website technologies also depends on the
        settings of the web browser you are using (e.g., Microsoft Edge, Google
        Chrome, Apple Safari, Mozilla Firefox). Most web browsers are preset to
        automatically accept certain types of website technologies. However, you
        can usually change this setting. You can delete stored website
        technologies at any time. Web/DOM storage and local shared objects can
        be deleted separately. You can find out how this works in the
        instructions of the provider of the browser or device you are using.
      </p>
      <p>
        <strong>e.</strong> The consent to, and rejection or deletion of,
        website technologies are tied to the device and also to the respective
        web browser you use. If you use multiple devices or web browsers, you
        can make decisions or settings differently.
      </p>
      <p>
        <strong>f.</strong> If you decide against the use of website
        technologies or delete them, you may not have access to all functions of
        our websites or individual functions may be limited.
      </p>

      <h2>6. Security</h2>
      <p>
        We take technical and organizational security measures to protect the
        data managed by us against manipulation, loss, destruction and access by
        unauthorized persons. We are continuously improving our security
        measures in line with technological advancements.
      </p>

      <h2>7. Legal Basis for Data Processing</h2>
      <p>
        <strong>a.</strong> If you have given us your consent to process your
        personal data, then that is the legal basis for data processing (Article
        6, para. 1, letter a, GDPR).
      </p>
      <p>
        <strong>b.</strong> Article 6, para. 1, letter b, GDPR is the legal
        basis for processing personal data for the purpose of entering into a
        contract or performing a contract with you.
      </p>
      <p>
        <strong>c.</strong> If processing your personal data is required to
        fulfill our legal obligations (e.g. data retention), we are authorized
        to do so by Article 6, para. 2, letter c, GDPR.
      </p>
      <p>
        <strong>d.</strong> Furthermore, we process personal data for purposes
        of the legitimate interests pursued by us or a third party in accordance
        with Article 6, para. 1, letter f GDPR. Examples of our legitimate
        interests include maintaining the functionality of our IT systems, the
        (direct) marketing of our products and services (to the extent not
        covered by your consent) and the legally required documentation of
        business contacts. As part of the balance of interests required in each
        case, we take into account various aspects, in particular the type of
        personal data, the purpose of processing, the circumstances of
        processing and your interest in the confidentiality of your personal
        data.
      </p>

      <h2>8. Deleting your personal data</h2>
      <p>
        Your IP address and the name of your Internet service provider, which we
        store for security reasons, are deleted after seven days. Moreover, we
        delete your personal data as soon as the purpose for which it was
        collected and processed has been fulfilled. Beyond this time period,
        data storage only takes place to the extent necessary by the
        legislation, regulations or other legal provisions to which we are
        subject in the EU or by legal provisions in third-party countries if
        these have an appropriate level of data protection. Should it not be
        possible to delete data in individual cases, the relevant personal data
        are flagged to restrict their further processing.
      </p>
      <h2>9. Data Subject Rights</h2>
      <p>
        <strong>a.</strong> As a data subject, you have the right of Access
        (Section 15 GDPR), Rectification (Article16 GDPR), Erasure (Article 17
        GDPR), Restriction of Processing (Article18 GDPR) and Data Portability
        (Article 20 GDPR).
      </p>
      <p>
        <strong>b.</strong> If you have consented to the processing of your
        personal data by us, you have the right to revoke your consent at any
        time. Your revocation does not affect the legality of the processing of
        your personal data that took place before your consent was revoked. It
        also has no effect on the continued processing of the data on another
        legal basis, such as to fulfill legal obligations (see "Legal Basis of
        Data Processing").
      </p>
      <p
        style={{
          border: '1px solid black',
          borderRadius: '6px',
          padding: '10px',
          margin: '0px -10px 10px -10px',
        }}
      >
        <strong>c.</strong> Right to object: For grounds relating to your
        particular situation, you have the right to object at any time to the
        processing of personal data concerning you that is collected under
        Article 6 Clause (1e) GDPR (data processing in the public interest) or
        Article 6 Clause 1 f) GDPR (data processing on the basis of a legitimate
        interests). If you file an objection, we will continue to process your
        personal data only if we can document mandatory, legitimate reasons that
        outweigh your interests, rights and freedoms, or if processing is for
        the assertion, exercise or defense of legal claims. To the extent we use
        your personal data for direct marketing based on legitimate interests,
        you have the right to object at any time without giving reasons.
      </p>
      <p>
        <strong>d.</strong> We ask you to address your claims or declarations to
        the following contact address if possible:{' '}
        <a href="mailto:dataprivacy-bus@daimlertruck.com">
          dataprivacy-bus@daimlertruck.com
        </a>
        .
      </p>
      <p>
        <strong>e.</strong> If you believe that the processing of your personal
        data violates legal provisions, you have the right to lodge a complaint
        with a competent data protection supervisory authority (Article 77
        GDPR).
      </p>

      <h2>10. Newsletter</h2>
      <p>
        If you subscribe to a newsletter offered on our website, the information
        provided during registration for the newsletter will be used solely for
        the purpose of mailing the newsletter unless you consent to its use for
        additional purposes. You may cancel the subscription at any time by
        using the option to unsubscribe contained in the newsletter.
      </p>

      <h2>11. Daimler Truck’s Central Registration Service</h2>
      <p>
        With the Central Registration Service offered by Daimler Truck, you can
        sign up for every website and application belonging to the Daimler Truck
        Group and its brands that are connected to the service. The applicable
        terms of use contain specific data protection provisions. Those terms of
        use can be found on the registration pages of affiliated websites and
        applications.
      </p>

      <h2>
        12. Data transmission to recipients outside the European Economic Area
      </h2>
      <p>
        <strong>a.</strong> When using service providers (see section 4. d.) and
        transferring data to third parties based on your consent (see section
        3.c.), personal data may be provided to recipients in countries outside
        the European Union ("EU"), Iceland, Liechtenstein and Norway (= European
        Economic Area). Such countries are, in particular, the USA, India.
      </p>
      <p>
        <strong>b.</strong> In the following countries (
        <a
          href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
          target="_blank"
          rel="noreferrer"
        >
          see list
        </a>
        ) there is an adequate level of data protection from the EU's point of
        view (so-called "adequacy decision"). With recipients in other countries
        we agree on the use of EU standard contractual clauses, binding
        corporate rules to achieve an "adequate level of protection" according
        to legal requirements. For more information, please use the contact
        details given in section 9.d. above.
      </p>

      <p>
        <strong>Last update: April 2024</strong>
      </p>
    </div>
  )
}

export default PrivacyEN
