import { useState } from 'react'
import { Row, Col, Card } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropertyMapper from './PropertyMapper'
import Form from '$components/Form'

const FormHeader = styled.h2`
  font-size: 1.2em;
  font-weight: 500;

  margin: 20px 0;
`

const MappingForm = ({
  name = 'mapDataForm',
  internalProperties,
  propertiesToMap,
  handleOk,
  data,
}) => {
  const { t } = useTranslation()
  const [mappedProperties, setMappedProperties] = useState([])

  return (
    <>
      <Card>{t('parts_master_data.upload.mapping_prompt')}</Card>

      <Form
        onFinish={handleOk}
        name={name}
        labelAlign="left"
        labelCol={{ span: 10 }}
        requiredMark={false}
        onValuesChange={(_, allValues) => {
          setMappedProperties(
            Object.entries(allValues)
              .filter(([key, _]) => !key.endsWith('-unit'))
              .map(([_, val]) => val)
              .filter((val) => val !== undefined && val !== null),
          )
        }}
      >
        <Row>
          <Col span={10}>
            <FormHeader>
              {t('parts_master_data.upload.required_fields')}
            </FormHeader>
          </Col>
          <Col span={14}>
            <FormHeader>
              {t('parts_master_data.upload.mapping_column_header')}
            </FormHeader>
          </Col>
        </Row>

        {internalProperties
          .filter((property) => property.required)
          .map((property) => (
            <PropertyMapper
              key={property.name}
              propertiesToMap={propertiesToMap}
              disableOptions={mappedProperties}
              data={data}
              {...property}
            />
          ))}

        <Row>
          <Col span={10}>
            <FormHeader>
              {t('parts_master_data.upload.optional_fields')}
            </FormHeader>
          </Col>
          <Col span={14}>
            <FormHeader>
              {t('parts_master_data.upload.mapping_column_header')}
            </FormHeader>
          </Col>
        </Row>

        {internalProperties
          .filter((property) => !property.required)
          .map((property) => (
            <PropertyMapper
              key={property.name}
              propertiesToMap={propertiesToMap}
              disableOptions={mappedProperties}
              data={data}
              {...property}
            />
          ))}
      </Form>
    </>
  )
}

export default MappingForm
