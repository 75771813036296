import { StyledLayout } from '../../globalStyledComponents/Layout'
import Footer from '../../components/Footer'
import { Button, Result } from 'antd'
import SusiPortraitImg from '../../assets/img/susi_portrait.png'

const { Content } = StyledLayout

const Supply = () => {
  return (
    <StyledLayout className="layout">
      <Content>
        <h1 style={{ margin: '50px 0' }}>Supply</h1>
        <Result
          status="warning"
          title="Work in progress."
          extra={<img src={SusiPortraitImg} />}
        />
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default Supply
