import {
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react'
import { Space, Button, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { fetchCompanyData } from '../../../api/evoAPIs'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { ColumnsType } from 'antd/es/table'
import { TableRowSelection } from 'antd/lib/table/interface'
import { StyledTable } from './styledComponents'
import { keycloakUrl } from '../../../api'
import axios from 'axios'
import { AuthContext } from '../../../context/AuthContextProvider'

interface CustomTableRowSelection extends TableRowSelection<ColumnsType> {
  onChange: (selectedRowKeys: React.Key[], selectedRows: ColumnsType[]) => void
}

interface DataType {
  id: string
  key: string
  name: any
  type: string
}
interface Props {
  company: any
  setCompanyID: Dispatch<SetStateAction<string>>
  setIsNewCompany: Dispatch<SetStateAction<boolean>>
  isNewCompany: boolean
  userCompany: any
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    className: 'userlistnamecol',
  },
  {
    title: 'Type',
    key: 'type',
    render: (record) => {
      return <Tag>{record?.type}</Tag>
    },
  },
  {
    title: 'Action',
    key: 'action',
    render: (record) => {
      return (
        <Space size="middle">
          <a>Edit</a>
        </Space>
      )
    },
  },
]

const tData = (data) =>
  Array.isArray(data) && data?.map((el, i) => ({ key: i, ...el }))

const Companylist = (props: Props) => {
  const authContext = useContext(AuthContext)
  const [data, setData] = useState<DataType[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedRow, setSelectedRow] = useState<DataType | undefined>(
    undefined,
  )
  const [count, setCount] = useState<string>('3')
  const queryClient = useQueryClient()

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys)
  }

  const onRow = (record: DataType) => {
    return {
      onClick: () => {
        setSelectedRow(record)
        setSelectedRowKeys([record.key])
        if (record.id) {
          props.setCompanyID(record.id)
        } else {
          queryClient.invalidateQueries(['companyuser'])
        }
      },
    }
  }

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const addCompany = () => {
    props.setIsNewCompany(true)
    const newData: DataType = {
      key: count,
      name: '',
      type: '',
      id: '',
    }
    setData([...data, newData])
  }

  useEffect(() => {
    // checks if users company is main or sub
    if (props.userCompany[0] != '') {
      if (props.userCompany[1] === true) {
        for (let i of props.userCompany[0].subGroups) {
          i.type = 'SUB'
        }
        setData([
          {
            key: '0',
            name: props.userCompany[0].name,
            type: 'MAIN',
          },
          ...props.userCompany[0].subGroups,
        ])
      } else {
        props.userCompany[0].type = 'SUB'
        setData([
          {
            key: '0',
            name: props.userCompany[0].name,
            type: 'SUB',
            id: props.userCompany[0].id,
          },
        ])
        // }
      }
    }
  }, [props.company])

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>Companies</h3>
        <Button
          type="primary"
          onClick={addCompany}
          disabled={
            props.isNewCompany ||
            !props.userCompany[1] ||
            (props.userCompany && !props.userCompany[0]?.access?.manage)
          }
        >
          <PlusOutlined />
        </Button>
      </div>
      <br />
      <StyledTable
        columns={columns}
        dataSource={tData(data)}
        rowSelection={rowSelection}
        onRow={onRow}
        rowKey={(record) => record.key}
      />
    </>
  )
}

export default Companylist
