import { useTranslation } from 'react-i18next'

import {
  PartNoBg,
  StyledTable,
} from '$pages/serviceuser/Marketplace/Bookservice/Defaultwizard/styledComponents'

const OrderPartsTableView = ({ dataSource }) => {
  const { t } = useTranslation()

  const createColumnsConfig = () => {
    const columns = [
      {
        title: t('Part No'),
        dataIndex: ['part', 'id_part_client'],
        render: (title) => <PartNoBg>{title}</PartNoBg>,
      },
      {
        title: t('Part Name'),
        dataIndex: ['part', 'name'],
      },
      {
        title: t('Material'),
        dataIndex: ['material', 'materialname'],
      },
      {
        title: t('Quantity'),
        dataIndex: 'amount',
      },
    ]
    return columns
  }

  return (
    <StyledTable
      size="middle"
      columns={createColumnsConfig()}
      style={{ width: '100%' }}
      dataSource={dataSource}
      pagination={false}
    />
  )
}
export default OrderPartsTableView
