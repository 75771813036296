import { localeParseFloat, numberRenderer } from '$utils/i18n'
import { InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'

const CustomInputNumber = ({ ...props }) => {
  const { i18n, t } = useTranslation()

  return (
    <InputNumber
      formatter={numberRenderer(t)}
      parser={(value) => localeParseFloat(value, i18n)}
      {...props}
    />
  )
}

export default CustomInputNumber
