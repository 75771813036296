import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Space, theme } from 'antd'
import { StyledOrdersandCustomerstotalCard } from '../globalStyledComponents/Card'
import ReactApexChart from 'react-apexcharts'
import { UilBox, UilShop } from '@iconscout/react-unicons'
import useServicesData from '$utils/useServicesData'

const { useToken } = theme

const stripLeadingSlash = (str) => str.replace(/^\//, '')

const Ordersandcustomerstotal = ({ serviceName }) => {
  const { t } = useTranslation()
  const { token } = useToken()

  const servicesData = useServicesData()
  const orders = servicesData.servicesData || []

  const filteredOrders =
    !serviceName || serviceName === 'All Services'
      ? orders
      : orders.filter((order) => order.service_name === serviceName)

  const processedServicesTotal = filteredOrders.filter(
    (obj) => obj.status_info === 'finished',
  ).length

  const ordersByCompanies = Object.groupBy(
    filteredOrders,
    ({ company }) => company,
  )
  const customersTotal = Object.keys(ordersByCompanies).length

  const companies =
    customersTotal > 0
      ? Object.entries(ordersByCompanies).map(([company, orders]) => ({
          name: stripLeadingSlash(company),
          color: token.colorPrimary,
          activeOrders: orders.filter(
            (obj) =>
              obj.status_info !== 'finished' ||
              obj.status_info !== 'stopped' ||
              obj.status_info !== 'canceled',
          ).length,
        }))
      : [
          {
            name: '',
            color: token.colorPrimary,
            activeOrders: 0,
          },
        ]

  const chartOptions = {
    chart: {
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          total: {
            show: !0,
            label: `${t('ordered_services.total')}:`,
            fontSize: '16px',
            color: token.colorText,
            fontFamily: void 0,
            fontWeight: 600,
            formatter: function (e) {
              return (
                e.globals.seriesTotals.reduce(function (e, t) {
                  return e + t
                }, 0) + ' Services'
              )
            },
          },
          value: {
            show: !0,
            fontSize: '14px',
            offsetY: 4,
            formatter: function (e) {
              return e + ' Services'
            },
          },
        },
        startAngle: 0,
        endAngle: 270,
      },
    },
    stroke: {
      lineCap: 'round',
    },
    fill: {
      type: 'solid',
    },
    colors: companies.map((company) => company.color),
    labels: companies.map((company) => company.name),
    legend: { show: !1 },
  }

  return (
    <React.Fragment>
      <StyledOrdersandCustomerstotalCard>
        <Row>
          <Col span={12}>
            <h3>{t('ordered_services.processed_services')}</h3>
            <p style={{ fontWeight: '500', fontSize: '20px' }}>
              <UilBox style={{ margin: '0px 7px -5px 0px', width: '21px' }} />
              {processedServicesTotal}
            </p>
          </Col>
          <Col span={12}>
            <h3>{t('ordered_services.customers_total')}</h3>
            <p style={{ fontWeight: '500', fontSize: '20px' }}>
              <UilShop style={{ margin: '0px 7px -5px 0px', width: '21px' }} />
              {customersTotal}
            </p>
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '100%' }}>
            <h3>{t('ordered_services.top_customers')}</h3>
            <div id="wallet-balance-chart">
              <ReactApexChart
                options={chartOptions}
                series={companies.map((company) => company.activeOrders)}
                type="radialBar"
                height={300}
                className="apex-charts"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Space size="large">
            {customersTotal > 0 &&
              companies.map((company) => (
                <div style={{ display: 'flex' }} key={company.name}>
                  <div className="traffic-light">
                    <svg viewBox="0 0 10 10">
                      <circle cx="50%" cy="50%" r="4" fill={company.color} />
                    </svg>
                  </div>
                  <span>{company.name}</span>
                </div>
              ))}
          </Space>
        </Row>
      </StyledOrdersandCustomerstotalCard>
    </React.Fragment>
  )
}

export default Ordersandcustomerstotal
