import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledDemoLayout } from '$globalStyledComponents/Layout'
import { StyledGlobal } from '$globalStyledComponents/Global'
import { isDemo } from '$utils/demo'

const Layout = ({ children }) => {
  const { t } = useTranslation()

  const renderBubbles = () => {
    const bubbles = []
    for (let i = 0; i < 50; i++) {
      bubbles.push(<div class="bubble" key={i} />)
    }
    return bubbles
  }

  if (!isDemo) {
    return <StyledGlobal>{children}</StyledGlobal>
  }

  return (
    <>
      <StyledGlobal>
        <StyledDemoLayout>
          <div
            class="bubbles"
            style={{
              backgroundColor: '#00566A',
              padding: '8px',
              textAlign: 'center',
              color: 'white',
            }}
          >
            {renderBubbles()}
            {t('demo.note')}
          </div>
        </StyledDemoLayout>
        {children}
      </StyledGlobal>
    </>
  )
}

export default Layout
