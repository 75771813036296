import {
  UilAnalytics,
  UilBrushAlt,
  UilCube,
  UilFileCheck,
  UilNotes,
  UilStore,
  UilTruck,
} from '@iconscout/react-unicons'
import {
  SmallServiceButton,
  SmServContainer,
  ServiceStatusButtonWrapper,
} from './styledComponents'

interface Performeableservices {
  variant: [
    {
      description: string
      key: string
      value: number
    },
  ]
}

const ServiceStatusButtons = (props: Performeableservices) => {
  // status:
  // 4 kann gebucht werden
  // 1 wurde gebucht und kann gebucht werden
  // 2 kann nicht gebucht werden
  // 3 wurde gebucht und kann nicht mehr gebucht werden
  // defaults:

  let feasible = 4
  let requestam = 4
  let generate = 4
  let branding = 4
  let texturing = 4
  let order = 4
  let digitalpartsreport = 4

  const variantKeys = Object.keys(props?.variant || {})

  variantKeys.forEach((key) => {
    const value = props?.variant[key]?.value
    switch (props?.variant[key]?.key) {
      case 'Feasibility Check':
        feasible = Number(value)
        break
      case 'fc_Order Part':
        feasible = 1
        break
      case 'Request AM Offer':
        requestam = Number(value)
        break
      case 'Generate 3D Model':
        generate = Number(value)
        break
      case 'Apply Branding':
        branding = Number(value)
        break
      case 'Apply Texturing':
        texturing = Number(value)
        break
      case 'Order Part':
        order = Number(value)
        break
      case 'Digital Parts Report':
        digitalpartsreport = Number(value)
        break
    }
  })

  const getFeasibleIcon = function (vari) {
    if (vari === 4) {
      return (
        <div className="clickableservice" title={'Feasibility Check'}>
          <UilFileCheck size="18" color="#495057" />
        </div>
      )
    } else if (vari === 2) {
      return (
        <div title={'Feasibility Check'}>
          <UilFileCheck size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM5.95371 5.9959L5.37363 5.99326L4.5 4.95176L3.62725 5.99238L3.04629 5.99502C3.00762 5.99502 2.97598 5.96426 2.97598 5.92471C2.97598 5.90801 2.98213 5.89219 2.99268 5.879L4.13613 4.5167L2.99268 3.15527C2.98206 3.14239 2.97616 3.12626 2.97598 3.10957C2.97598 3.0709 3.00762 3.03926 3.04629 3.03926L3.62725 3.04189L4.5 4.0834L5.37275 3.04277L5.95283 3.04014C5.9915 3.04014 6.02314 3.0709 6.02314 3.11045C6.02314 3.12715 6.01699 3.14297 6.00645 3.15615L4.86475 4.51758L6.00732 5.87988C6.01787 5.89307 6.02402 5.90889 6.02402 5.92559C6.02402 5.96426 5.99238 5.9959 5.95371 5.9959Z"
              fill="#CD0204"
            />
          </svg>
        </div>
      )
    } else if (vari === 3) {
      return (
        <div title={'Feasibility Check'}>
          <UilFileCheck size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    } else if (vari === 1) {
      return (
        <div className="clickableservice" title={'Feasibility Check'}>
          <UilFileCheck size="18" color="#495057" />
          <svg
            style={{ margin: '0 0 -12px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    }
  }
  const getRequestIcon = function (vari) {
    if (vari === 4) {
      return (
        <div className="clickableservice" title={'Request AM Offer'}>
          <UilNotes size="18" color="#495057" />
        </div>
      )
    } else if (vari === 2) {
      return (
        <div title={'Request AM Offer'}>
          <UilNotes size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM5.95371 5.9959L5.37363 5.99326L4.5 4.95176L3.62725 5.99238L3.04629 5.99502C3.00762 5.99502 2.97598 5.96426 2.97598 5.92471C2.97598 5.90801 2.98213 5.89219 2.99268 5.879L4.13613 4.5167L2.99268 3.15527C2.98206 3.14239 2.97616 3.12626 2.97598 3.10957C2.97598 3.0709 3.00762 3.03926 3.04629 3.03926L3.62725 3.04189L4.5 4.0834L5.37275 3.04277L5.95283 3.04014C5.9915 3.04014 6.02314 3.0709 6.02314 3.11045C6.02314 3.12715 6.01699 3.14297 6.00645 3.15615L4.86475 4.51758L6.00732 5.87988C6.01787 5.89307 6.02402 5.90889 6.02402 5.92559C6.02402 5.96426 5.99238 5.9959 5.95371 5.9959Z"
              fill="#CD0204"
            />
          </svg>
        </div>
      )
    } else if (vari === 3) {
      return (
        <div title={'Request AM Offer'}>
          <UilNotes size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    } else if (vari === 1) {
      return (
        <div className="clickableservice" title={'Request AM Offer'}>
          <UilNotes size="18" color="#495057" />
          <svg
            style={{ margin: '0 0 -12px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    }
  }
  const get3DIcon = function (vari) {
    if (vari === 4) {
      return (
        <div className="clickableservice" title={'Generate 3D Model'}>
          <UilCube size="18" color="#495057" />
        </div>
      )
    } else if (vari === 2) {
      return (
        <div title={'Generate 3D Model'}>
          <UilCube size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM5.95371 5.9959L5.37363 5.99326L4.5 4.95176L3.62725 5.99238L3.04629 5.99502C3.00762 5.99502 2.97598 5.96426 2.97598 5.92471C2.97598 5.90801 2.98213 5.89219 2.99268 5.879L4.13613 4.5167L2.99268 3.15527C2.98206 3.14239 2.97616 3.12626 2.97598 3.10957C2.97598 3.0709 3.00762 3.03926 3.04629 3.03926L3.62725 3.04189L4.5 4.0834L5.37275 3.04277L5.95283 3.04014C5.9915 3.04014 6.02314 3.0709 6.02314 3.11045C6.02314 3.12715 6.01699 3.14297 6.00645 3.15615L4.86475 4.51758L6.00732 5.87988C6.01787 5.89307 6.02402 5.90889 6.02402 5.92559C6.02402 5.96426 5.99238 5.9959 5.95371 5.9959Z"
              fill="#CD0204"
            />
          </svg>
        </div>
      )
    } else if (vari === 3) {
      return (
        <div title={'Generate 3D Model'}>
          <UilCube size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    } else if (vari === 1) {
      return (
        <div className="clickableservice" title={'Generate 3D Model'}>
          <UilCube size="18" color="#495057" />
          <svg
            style={{ margin: '0 0 -12px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    }
  }
  const getBrandingIcon = function (vari) {
    if (vari === 4) {
      return (
        <div className="clickableservice" title={'Apply Branding'}>
          <UilStore size="18" color="#495057" />
        </div>
      )
    } else if (vari === 2) {
      return (
        <div title={'Apply Branding'}>
          <UilStore size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM5.95371 5.9959L5.37363 5.99326L4.5 4.95176L3.62725 5.99238L3.04629 5.99502C3.00762 5.99502 2.97598 5.96426 2.97598 5.92471C2.97598 5.90801 2.98213 5.89219 2.99268 5.879L4.13613 4.5167L2.99268 3.15527C2.98206 3.14239 2.97616 3.12626 2.97598 3.10957C2.97598 3.0709 3.00762 3.03926 3.04629 3.03926L3.62725 3.04189L4.5 4.0834L5.37275 3.04277L5.95283 3.04014C5.9915 3.04014 6.02314 3.0709 6.02314 3.11045C6.02314 3.12715 6.01699 3.14297 6.00645 3.15615L4.86475 4.51758L6.00732 5.87988C6.01787 5.89307 6.02402 5.90889 6.02402 5.92559C6.02402 5.96426 5.99238 5.9959 5.95371 5.9959Z"
              fill="#CD0204"
            />
          </svg>
        </div>
      )
    } else if (vari === 3) {
      return (
        <div title={'Apply Branding'}>
          <UilStore size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    } else if (vari === 1) {
      return (
        <div className="clickableservice" title={'Apply Branding'}>
          <UilStore size="18" color="#495057" />
          <svg
            style={{ margin: '0 0 -12px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    }
  }
  const getTexuringIcon = function (vari) {
    if (vari === 4) {
      return (
        <div className="clickableservice" title={'Apply Texturing'}>
          <UilBrushAlt size="18" color="#495057" />
        </div>
      )
    } else if (vari === 2) {
      return (
        <div title={'Apply Texturing'}>
          <UilBrushAlt size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM5.95371 5.9959L5.37363 5.99326L4.5 4.95176L3.62725 5.99238L3.04629 5.99502C3.00762 5.99502 2.97598 5.96426 2.97598 5.92471C2.97598 5.90801 2.98213 5.89219 2.99268 5.879L4.13613 4.5167L2.99268 3.15527C2.98206 3.14239 2.97616 3.12626 2.97598 3.10957C2.97598 3.0709 3.00762 3.03926 3.04629 3.03926L3.62725 3.04189L4.5 4.0834L5.37275 3.04277L5.95283 3.04014C5.9915 3.04014 6.02314 3.0709 6.02314 3.11045C6.02314 3.12715 6.01699 3.14297 6.00645 3.15615L4.86475 4.51758L6.00732 5.87988C6.01787 5.89307 6.02402 5.90889 6.02402 5.92559C6.02402 5.96426 5.99238 5.9959 5.95371 5.9959Z"
              fill="#CD0204"
            />
          </svg>
        </div>
      )
    } else if (vari === 3) {
      return (
        <div title={'Apply Texturing'}>
          <UilBrushAlt size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    } else if (vari === 1) {
      return (
        <div className="clickableservice" title={'Apply Texturing'}>
          <UilBrushAlt size="18" color="#495057" />
          <svg
            style={{ margin: '0 0 -12px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    }
  }
  const getOrderpartIcon = function (vari) {
    if (vari === 4) {
      return (
        <div className="clickableservice" title={'Order Part'}>
          <UilTruck size="18" color="#495057" />
        </div>
      )
    } else if (vari === 2) {
      return (
        <div title={'Order Part'}>
          <UilTruck size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM5.95371 5.9959L5.37363 5.99326L4.5 4.95176L3.62725 5.99238L3.04629 5.99502C3.00762 5.99502 2.97598 5.96426 2.97598 5.92471C2.97598 5.90801 2.98213 5.89219 2.99268 5.879L4.13613 4.5167L2.99268 3.15527C2.98206 3.14239 2.97616 3.12626 2.97598 3.10957C2.97598 3.0709 3.00762 3.03926 3.04629 3.03926L3.62725 3.04189L4.5 4.0834L5.37275 3.04277L5.95283 3.04014C5.9915 3.04014 6.02314 3.0709 6.02314 3.11045C6.02314 3.12715 6.01699 3.14297 6.00645 3.15615L4.86475 4.51758L6.00732 5.87988C6.01787 5.89307 6.02402 5.90889 6.02402 5.92559C6.02402 5.96426 5.99238 5.9959 5.95371 5.9959Z"
              fill="#CD0204"
            />
          </svg>
        </div>
      )
    } else if (vari === 3) {
      return (
        <div title={'Order Part'}>
          <UilTruck size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    } else if (vari === 1) {
      return (
        <div className="clickableservice" title={'Order Part'}>
          <UilTruck size="18" color="#495057" />
          <svg
            style={{ margin: '0 0 -12px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    }
  }
  const getPartsreportIcon = function (vari) {
    if (vari === 4) {
      return (
        <div className="clickableservice" title={'Digital Parts Report'}>
          <UilAnalytics size="18" color="#495057" />
        </div>
      )
    } else if (vari === 2) {
      return (
        <div title={'Digital Parts Report'}>
          <UilAnalytics size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM5.95371 5.9959L5.37363 5.99326L4.5 4.95176L3.62725 5.99238L3.04629 5.99502C3.00762 5.99502 2.97598 5.96426 2.97598 5.92471C2.97598 5.90801 2.98213 5.89219 2.99268 5.879L4.13613 4.5167L2.99268 3.15527C2.98206 3.14239 2.97616 3.12626 2.97598 3.10957C2.97598 3.0709 3.00762 3.03926 3.04629 3.03926L3.62725 3.04189L4.5 4.0834L5.37275 3.04277L5.95283 3.04014C5.9915 3.04014 6.02314 3.0709 6.02314 3.11045C6.02314 3.12715 6.01699 3.14297 6.00645 3.15615L4.86475 4.51758L6.00732 5.87988C6.01787 5.89307 6.02402 5.90889 6.02402 5.92559C6.02402 5.96426 5.99238 5.9959 5.95371 5.9959Z"
              fill="#CD0204"
            />
          </svg>
        </div>
      )
    } else if (vari === 3) {
      return (
        <div title={'Digital Parts Report'}>
          <UilAnalytics size="18" color="#495057" />
          <svg
            style={{ margin: '0px 0 -4px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    } else if (vari === 1) {
      return (
        <div className="clickableservice" title={'Digital Parts Report'}>
          <UilAnalytics size="18" color="#495057" />
          <svg
            style={{ margin: '0 0 -12px -7px' }}
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.5 0.5625C2.32559 0.5625 0.5625 2.32559 0.5625 4.5C0.5625 6.67441 2.32559 8.4375 4.5 8.4375C6.67441 8.4375 8.4375 6.67441 8.4375 4.5C8.4375 2.32559 6.67441 0.5625 4.5 0.5625ZM6.20068 3.21416L4.34971 5.78057C4.32384 5.81668 4.28973 5.8461 4.25022 5.86639C4.21071 5.88669 4.16693 5.89728 4.12251 5.89728C4.07809 5.89728 4.03431 5.88669 3.9948 5.86639C3.95529 5.8461 3.92118 5.81668 3.89531 5.78057L2.79932 4.26182C2.76592 4.21523 2.79932 4.1502 2.85645 4.1502H3.26865C3.3583 4.1502 3.44355 4.19326 3.49629 4.26709L4.12207 5.13545L5.50371 3.21943C5.55645 3.14648 5.64082 3.10254 5.73135 3.10254H6.14355C6.20068 3.10254 6.23408 3.16758 6.20068 3.21416Z"
              fill="#7CC334"
            />
          </svg>
        </div>
      )
    }
  }

  return (
    <ServiceStatusButtonWrapper>
      <SmServContainer>
        <SmallServiceButton>{getFeasibleIcon(feasible)}</SmallServiceButton>{' '}
      </SmServContainer>
      <SmServContainer>
        <SmallServiceButton>{getRequestIcon(requestam)}</SmallServiceButton>{' '}
      </SmServContainer>
      <SmallServiceButton>{get3DIcon(generate)}</SmallServiceButton>
      <SmallServiceButton>{getBrandingIcon(branding)}</SmallServiceButton>
      <SmallServiceButton>{getTexuringIcon(texturing)}</SmallServiceButton>
      <SmallServiceButton>{getOrderpartIcon(order)}</SmallServiceButton>
      <SmallServiceButton>
        {getPartsreportIcon(digitalpartsreport)}
      </SmallServiceButton>
    </ServiceStatusButtonWrapper>
  )
}
export default ServiceStatusButtons
