import { Steps, Table } from 'antd'
import styled from 'styled-components'

export const StyledPeriods = styled.div`
  display: flex;
  justify-content: space-between;
`
export const StyledDiscount = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px;
  :hover {
    background: #f5f5f5;
  }
`

export const StyledAddress = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
  gap: 20px;
`

export const StyledLink = styled.span`
  cursor: pointer;
`

export const StyledSteps = styled(Steps)`
  [class^='ant-steps'] {
    width: 150px;
  }
`
export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  tr {
    background-color: white;
    //cursor: pointer;
  }

  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    width: 9%;
  }
`
