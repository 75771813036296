import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { App, Button, Popconfirm } from 'antd'
import { useContext, useEffect, useState, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { evoConsolidation } from '$api'
import { evoClient, putPart } from '$api/client'
import {
  fetchAllServices,
  postApplyTexturing,
  postPartsConsolidation,
} from '$api/evoAPIs'
import { AuthContext } from '$context/AuthContextProvider'
import { updateStatusAttributes } from '$utils'
import StatusPage from '../Defaultwizard/StatusPage'
import StepOneSelection from '../Defaultwizard/StepOneSelection'
import StepThreeSummary from '../Defaultwizard/StepThreeSummary'
import {
  StyledStepButtons,
  StyledSteps,
} from '../Defaultwizard/styledComponents'
import StepTwoTexturingConfig from './StepTwoTexturingConfig'

const ApplyTexturing = (props) => {
  const { t } = useTranslation()
  const [fcData] = useState({
    not_feasible_parts: [],
    feasible_parts: [],
  })
  const [bookableParts, setBookableParts] = useState()
  const [steps, setSteps] = useState([
    {
      title: t('service.selection'),
      content: '',
    },
    {
      title: t('service.apply_texturing.step_config_title'),
      content: '',
    },
    {
      title: t('service.order'),
      content: '',
    },
  ])
  const [current, setCurrent] = useState(0)
  const { message } = App.useApp()
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(true)
  const [currentFormValues, setCurrentFormValues] = useState()
  const [configState, setConfigState] = useState(true)
  const [accumulatedFormData, setAccumulatedFormData] = useState([])
  const [currentApplyTexturingId, setCurrentApplyTexturingId] = useState()
  const [stlFile, setSTLFile] = useState()
  const [stlFileName, setSTLFileName] = useState()
  const [bumpMapFile, setBumpMapFile] = useState()
  const [bumpMapName, setBumpMapName] = useState()

  const queryClient = useQueryClient()
  const authContext = useContext(AuthContext)
  const hasRunupdateTexturingFiles = useRef(false)
  const { data: servicesData } = useQuery(['services'], fetchAllServices)

  const addBookedApplyTexturings = useMutation(
    (formData) => {
      formData.service_name = 'Apply Texturing'
      formData.service_id = props.bookedServiceStatus[1].id
      formData.partlist_name = props.activeGroupName
      formData.part.files = []
      setSTLFile(formData.stl_file)
      setBumpMapFile(formData.user_texture_file)
      delete formData.stl_file
      delete formData.user_texture_file
      return evoConsolidation.post(postApplyTexturing, formData, {
        headers: {
          'content-type': 'application/json',
        },
      })
    },
    {
      onSuccess: (data) => {
        setCurrentApplyTexturingId(data.data.id)
        // upload STL & Branding Image File
        uploadSTLFile.mutate(data.data.part.db_id_client)
        uploadBumpmap.mutate(data.data.part.db_id_client)
        // update Part status attribute
        updateClientPartStatus.mutate(data.data)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not book Service`)
      },
    },
  )

  const uploadSTLFile = useMutation(
    (conslidation_part_id) => {
      const formData = new FormData()
      const file = new File([stlFile], 'dateiname.stl', {
        type: 'application/vnd.ms-pki.stl',
      })
      formData.append('file', file)
      return evoConsolidation.post(
        `${postPartsConsolidation}${conslidation_part_id}/file`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
    },
    {
      onSuccess: (data) => {
        setSTLFileName(data.data)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not upload STL File`)
      },
    },
  )

  const uploadBumpmap = useMutation(
    (conslidation_part_id) => {
      const formData = new FormData()
      formData.append('file', bumpMapFile, 'name.png')
      return evoConsolidation.post(
        `${postPartsConsolidation}${conslidation_part_id}/file`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
          },
        },
      )
    },
    {
      onSuccess: (data) => {
        setBumpMapName(data.data)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could Upload Branding Image`)
      },
    },
  )

  const patchBrandingFiles = useMutation(
    (data) => {
      const body = JSON.stringify([data[1], data[2]])
      const applyTexturingid = data[0]
      return evoConsolidation.patch(
        `${postApplyTexturing}${applyTexturingid}`,
        body,
        {
          headers: {
            'content-type': 'application/json',
          },
        },
      )
    },
    {
      onSuccess: () => {
        setLoading(false)
      },
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could Upload Branding Image`)
      },
    },
  )

  const updateClientPartStatus = useMutation(
    (partsToUpdate) => {
      //check if "partsToUpdate" is from feasibility check or booked service
      if (partsToUpdate.part) {
        const editedPart = updateStatusAttributes(
          partsToUpdate.part,
          'status_attributes',
          'Apply Texturing',
          '1',
        )
        editedPart.owner = authContext.user.username
        editedPart.png = 'nopng'
        return evoClient.put(
          `${putPart}${editedPart.db_id_client}`,
          editedPart,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['allgroups'])
        queryClient.invalidateQueries(['notifications'])
      },
      onError: (err) => {
        message.error(`${err.response.status} Could not update status`)
      },
    },
  )

  // Functions

  const updateAccumulatedFormData = useCallback(() => {
    if (currentFormValues) {
      setAccumulatedFormData((prevData) => {
        if (
          prevData.some(
            (item) => item.part.db_id_client === currentFormValues.db_id_client,
          )
        ) {
          return prevData.map((item) =>
            item.part.db_id_client === currentFormValues.part.db_id_client
              ? currentFormValues
              : item,
          )
        } else {
          return [...prevData, currentFormValues]
        }
      })
    }
  }, [currentFormValues])

  const updateTexturingFiles = useCallback(() => {
    if (bumpMapName && stlFileName) {
      const nameArr = [currentApplyTexturingId, bumpMapName, stlFileName]
      patchBrandingFiles.mutate(nameArr)
      // this is for preventing infinite loop
      hasRunupdateTexturingFiles.current = true
    }
  }, [bumpMapName, stlFileName, currentApplyTexturingId, patchBrandingFiles])

  const updateSteps = useCallback(() => {
    if (bookableParts || servicesData) {
      const newSteps = [
        {
          title: t('service.selection'),
          content: (
            <StepOneSelection
              fcData={fcData}
              selectedParts={props.selectedParts}
              setBookableParts={setBookableParts}
              bookableParts={bookableParts}
              servicesData={servicesData}
              bookedServiceStatus={props.bookedServiceStatus}
            />
          ),
        },
        {
          title: t('service.apply_texturing.step_config_title'),
          content: (
            <StepTwoTexturingConfig
              fcData={fcData}
              selectedParts={props.selectedParts}
              bookableParts={bookableParts}
              setCurrentFormValues={setCurrentFormValues}
              setConfigState={setConfigState}
            />
          ),
        },
        {
          title: t('service.order'),
          content: (
            <StepThreeSummary
              fcData={fcData}
              selectedParts={props.selectedParts}
              bookableParts={bookableParts}
              setButtonActive={setButtonActive}
            />
          ),
        },
      ]
      setSteps(newSteps)
    }
  }, [
    bookableParts,
    servicesData,
    fcData,
    props.selectedParts,
    setBookableParts,
    setCurrentFormValues,
    setConfigState,
    setButtonActive,
    props.bookedServiceStatus,
    t,
  ])

  const handleOk = () => {
    setCurrent(0)
    props.setSelectedParts([])
    setButtonActive(true)

    //post to endpoint
    for (let formData of accumulatedFormData) {
      addBookedApplyTexturings.mutate(formData)
    }
    setLoading(true)
    setVisible(false)
  }

  const next = () => {
    if (current === 0) {
      setConfigState(false)
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    if (current === 1) {
      setConfigState(true)
    }
    setCurrent(current - 1)
  }
  const items = steps
    .filter(function (x) {
      return x !== null
    })
    .map((item) => ({
      key: item.title,
      title: item.title,
    }))

  useEffect(() => {
    updateSteps()
  }, [updateSteps])

  useEffect(() => {
    updateAccumulatedFormData()
  }, [updateAccumulatedFormData])

  useEffect(() => {
    if (!hasRunupdateTexturingFiles.current) {
      updateTexturingFiles()
    }
  }, [updateTexturingFiles])

  useEffect(() => {
    if (bookableParts && bookableParts.bookableParts.length === 0) {
      setConfigState(false)
    }
  }, [bookableParts])

  return (
    <>
      {visible && (
        <>
          <h1 style={{ margin: '50px 0' }}>
            {t(props.bookedServiceStatus[1].servicename)}
          </h1>
          <div className="contentcard">
            <StyledSteps current={current} items={items} type="navigation" />
            <div style={{ margin: '50px 0px' }}>{steps[current].content}</div>
            <StyledStepButtons>
              {current < steps.length - 1 && (
                <Popconfirm
                  disabled={configState}
                  placement="topRight"
                  description={
                    <>
                      Some parts need configuration before proceeding.
                      <br />
                      Check the "status" of your part.
                    </>
                  }
                  title={<h4>Some changes have to be made</h4>}
                >
                  <Button
                    type="primary"
                    onClick={() => next()}
                    disabled={!configState}
                  >
                    {t('button.next')}
                  </Button>
                </Popconfirm>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => handleOk()}
                  disabled={!buttonActive}
                >
                  {t('button.order')}
                </Button>
              )}
              {current > 0 && (
                <Button
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prev()}
                >
                  {t('button.back')}
                </Button>
              )}
            </StyledStepButtons>
          </div>
        </>
      )}
      {!visible && (
        <>
          <StatusPage
            setIsBookModalOpen={props.setIsBookModalOpen}
            loading={loading}
            setCurrent={props.setCurrent}
          />
        </>
      )}
    </>
  )
}
export default ApplyTexturing
