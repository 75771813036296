import { useTranslation } from 'react-i18next'
import TexturingConfigTable from './TexturingConfigTable'

const StepTwoTexturingConfig = (props: any) => {
  const { t } = useTranslation()

  return (
    <>
      <h2 style={{ margin: '50px 0' }}>{t('service.apply_texturing.title')}</h2>
      <TexturingConfigTable
        bookableParts={props.bookableParts}
        setCurrentFormValues={props.setCurrentFormValues}
        setConfigState={props.setConfigState}
      />
      <div style={{ marginTop: '20px' }}></div>
    </>
  )
}

export default StepTwoTexturingConfig
