import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox } from 'antd'
import LegalTextModal from './LegalTextModal'
import Form from '$components/Form'

const CheckBoxForm = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [checked, setChecked] = useState(false)
  const [open, setOpen] = useState(false)

  const onCheckboxChange = async (e) => {
    setChecked(e.target.checked)
    props.setButtonActive(e.target.checked)
    form.validateFields(['checkbox'])
  }

  const submit = async () => {
    try {
      await form.validateFields()
    } catch (errorInfo) {
      console.error('Failed:', errorInfo)
    }
  }

  const validation = (rule, value, callback = () => {}) => {
    if (checked) {
      return callback()
    }
    return callback(t('bookedservices.please_accept_legal'))
  }

  useEffect(() => {
    submit()
  }, [props.legalTextConfirmed]) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    props.setButtonActive(false)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const openLegalTextModal = () => {
    setOpen(true)
  }

  return (
    <Form form={form} name="checkbox-validation" style={{ textAlign: 'right' }}>
      {open ? (
        <LegalTextModal
          open={open}
          setOpen={setOpen}
          legalText={props.legalText}
        />
      ) : null}
      <p>{t('bookedservices.confirm_data_transfer')}</p>
      <p>
        {t('bookedservices.see_legal_text')}{' '}
        <b>
          <em>
            <span onClick={openLegalTextModal} style={{ cursor: 'pointer' }}>
              {t('bookedservices.legal_text')}
            </span>
          </em>
        </b>
      </p>
      <Form.Item name="checkbox" rules={[{ validator: validation }]}>
        <Checkbox checked={checked} onChange={onCheckboxChange}>
          {t('bookedservices.confirm_legal')}
        </Checkbox>
      </Form.Item>
    </Form>
  )
}

export default CheckBoxForm
