import styled from 'styled-components'
import { Button, Table } from 'antd'

export const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledButton = styled(Button)`
  background: ${(props) => props.theme.colorBgLightOne};
  border-color: ${(props) => props.theme.colorBgLightSix};
`

export const GroupedDiv = styled.div`
  background: ${(props) => props.theme.colorBgLightOne};
  padding: 15px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
`
export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    //margin-top: -6px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  tr {
    background-color: white;
    //cursor: pointer;
  }

  th::before {
    display: none;
  }

  .feas-col {
    width: 80px;
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #f8f8f8;
  }
  .ant-table-thead .ant-table-row-expand-icon-cell:after {
    content: 'Part No';
  }
  .ant-table-thead .ant-table-row-expand-icon-cell {
    min-width: 60px;
  }
`
