import { useEffect } from 'react'
import { Col, Row, Avatar, Button, Popover, Modal } from 'antd'
import {
  UserOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons'
import mymatpic from '../../../assets/img/mymatpic.png'

const Topwelcomecard = (props) => {
  const popcontent = (
    <div>
      <p>Some file restrictions</p>
      <p>
        Lorem ipsum dolor sit amet, <br />
        consetetur sadipscing elitr, <br />
        sed diam nonumy eirmod tempor <br />
        invidunt ut labore et dolore magna <br />
        aliquyam erat, sed diam voluptua.{' '}
      </p>
    </div>
  )

  return (
    <>
      <Row className="myevocard">
        <Col span={8}>
          <Row>
            <img src={mymatpic} width="135px" />
          </Row>
        </Col>
        <Col
          span={8}
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            textAlign: 'center',
          }}
        >
          <Col style={{ width: '50%' }}>
            Unmapped Materials<h2>12{props.partsCount}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            Client Materials<h2>23{props.currentgrouppartscounter}</h2>
          </Col>
          <Col style={{ width: '50%' }}>
            DWH Materials<h2>34{props.groupcounter}</h2>
          </Col>
        </Col>
        <Col span={8} style={{ textAlign: 'right', margin: 'auto' }}>
          <div style={{ width: '149px', display: 'inline-block' }}></div>
        </Col>
      </Row>
    </>
  )
}

export default Topwelcomecard
