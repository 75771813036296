import { Popover } from 'antd'
import { useTranslation } from 'react-i18next'

export const numberRenderer =
  (t, params = {}) =>
  (value) => {
    if (typeof value === 'undefined') {
      return ''
    }
    return t('localized_number', {
      val: value,
      ...params,
    })
  }

export const currencyRenderer = (t) => (value) =>
  t('localized_currency', {
    val: value,
  })

export const dateWithTimeRenderer = (t) => (value) => {
  if (!value) {
    return null
  }

  const date = t('localized_datetime', {
    val: new Date(value),
    formatParams: {
      val: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
    },
  })
  const datetime = t('localized_datetime', {
    val: new Date(value),
    formatParams: {
      val: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  })
  return (
    <Popover class="datetime" content={datetime}>
      {date}
    </Popover>
  )
}

export const CurrencyWithoutUnit = ({ value }) => {
  const { t } = useTranslation()

  if (typeof value === 'undefined') {
    return ''
  }

  return t('localized_number', {
    val: value,
    minimumFractionDigits: 2,
  })
}

export const DateTime = ({ value, withoutTime = false }) => {
  const { t } = useTranslation()

  if (!value) {
    return null
  }

  const val = new Date(value)

  let format = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  }
  if (withoutTime) {
    format = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }
  }

  return t('localized_datetime', {
    val,
    formatParams: {
      val: format,
    },
  })
}

export const localeParseFloat = (val, i18n) => {
  const locale = i18n.language
  // Determine the correct decimal separator based on the locale
  const decimalSeparator = locale === 'en' ? '.' : ','
  const thousandsSeparator = locale === 'en' ? ',' : '.'

  // Remove thousand separators, and put a point where the decimal separator occurs
  const str = Array.from(val, (c) =>
    c === thousandsSeparator ? '' : c === decimalSeparator ? '.' : c,
  ).join('')

  // Now it can be parsed
  return Number.parseFloat(str)
}
