import { Row, Col, Card } from 'antd'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import PropertyInput from './PropertyInput'
import Form from '$components/Form'

const FormHeader = styled.h2`
  font-size: 1.2em;
  font-weight: 500;

  margin: 20px 0;
`

const InputForm = ({ name, internalProperties, handleOk }) => {
  const { t } = useTranslation()

  return (
    <>
      <Card>{t('parts_master_data.create.mapping_prompt')}</Card>

      <Form
        onFinish={handleOk}
        name={name}
        labelAlign="left"
        labelCol={{ span: 10 }}
        requiredMark={false}
      >
        <Row>
          <Col span={10}>
            <FormHeader>
              {t('parts_master_data.upload.required_fields')}
            </FormHeader>
          </Col>
          <Col span={14}></Col>
        </Row>

        {internalProperties
          .filter((property) => property.required)
          .map((property) => (
            <PropertyInput key={property.name} {...property} />
          ))}

        <Row>
          <Col span={10}>
            <FormHeader>
              {t('parts_master_data.upload.optional_fields')}
            </FormHeader>
          </Col>
          <Col span={14}></Col>
        </Row>

        {internalProperties
          .filter((property) => !property.required)
          .map((property) => (
            <PropertyInput key={property.name} {...property} />
          ))}
      </Form>
    </>
  )
}

export default InputForm
