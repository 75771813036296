import { Table, Tag } from 'antd'

const createColumns = (t) => {
  const columns = [
    {
      title: 'Feasible',
      className: 'feas-col',
      render: (record) => {
        const fCValue = record?.attributes?.find(
          (x) => x.key === 'Feasibility Check',
        )?.value
        const fill =
          fCValue === 'True'
            ? '#34C38F'
            : fCValue === 'False'
            ? '#CD0204'
            : '#F1B44C'
        return (
          <div className="traffic-light">
            <svg viewBox="0 0 12 12">
              <circle cx="60%" cy="60%" r="4" fill={fill} />
            </svg>
          </div>
        )
      },
    },
    Table.EXPAND_COLUMN,

    {
      title: t('Part Name'),
      dataIndex: 'name',
    },
    {
      title: t('Width'),
      render: (record) =>
        record.float_attributes.find((x) => x.key === 'Width')?.value,
    },
    {
      title: t('Depth'),
      render: (record) =>
        record.float_attributes.find((x) => x.key === 'Depth')?.value,
    },
    {
      title: t('Height'),
      render: (record) =>
        record.float_attributes.find((x) => x.key === 'Height')?.value,
    },
    {
      title: `${t('Weight')} (g)`,
      render: (record) =>
        record.float_attributes.find((x) => x.key === 'Weight')?.value,
    },
    {
      title: t('Material'),
      render: (record) =>
        record.attributes.find((x) => x.key === 'Material')?.value,
    },
    {
      title: t('Status'),
      render: (record) => {
        if (record.value != null) {
          if (record.value === true) {
            return <Tag color="green">{t('finished')}</Tag>
          } else {
            return <Tag>{t('unfinished')}</Tag>
          }
        }
      },
    },
  ]

  return columns
}

export default createColumns
