import { useQuery } from '@tanstack/react-query'
import { fetchAllBookedServices, fetchAllOrderPartsLists } from '$api/evoAPIs'
import { fetchAllParts } from '$api/client'
import { useTranslation } from 'react-i18next'

function useServiceKPI(service) {
  const { t } = useTranslation()
  // Fetch all parts data
  const partsQuery = useQuery(['allparts'], fetchAllParts, {
    placeholderData: { items: [] },
    enabled:
      service.id === '63dcfb10f81b69c6d3a7c431' ||
      service.id === '63dcfb10f81b69c6d3a7c42f',
    refetchOnWindowFocus: false,
  })

  // Fetch all booked services data (no Order Parts)
  const servicesQuery = useQuery(['bookedServices'], fetchAllBookedServices, {
    placeholderData: { items: [] },
    enabled: service.id === 'main',
    refetchOnWindowFocus: false,
  })

  // Fetch order parts
  const orderedServicesQuery = useQuery(
    ['orderPartsLists'],
    fetchAllOrderPartsLists,
    {
      enabled: service.id === 'main',
      refetchOnWindowFocus: false,
    },
  )

  // Determine type of service button
  switch (service.id) {
    case '63dcfb10f81b69c6d3a7c431':
      if (partsQuery.isLoading) return <span>{t('Loading...')}</span>
      if (partsQuery.isError) return <span>{t('Error occurred')}</span>
      const feasibilityTrue = partsQuery.data.items.filter((item) =>
        item.attributes.find(
          (attr) => attr.key === 'Feasibility Check' && attr.value === 'true',
        ),
      ).length
      const feasibilityFalse = partsQuery.data.items.filter((item) =>
        item.attributes.find(
          (attr) => attr.key === 'Feasibility Check' && attr.value === 'false',
        ),
      ).length

      return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <svg viewBox="0 0 12 12" width="12" height="12">
            <circle cx="6" cy="6" r="4" fill="#34C38F" />
          </svg>
          {feasibilityTrue} /
          <svg viewBox="0 0 12 12" width="12" height="12">
            <circle cx="6" cy="6" r="4" fill="#CD0204" />
          </svg>
          {`${feasibilityFalse} ${t('marketplace.of')} ${t(
            'marketplace.parts',
            {
              count: partsQuery.data.items.length,
            },
          )} ${t('marketplace.checked')}`}
        </div>
      )

    case 'main':
      if (servicesQuery.isLoading) return <span>{t('Loading...')}</span>
      if (servicesQuery.isError) return <span>{t('Error occurred')}</span>

      const kpiData = service.subservices.flatMap(
        (subservice, index, array) => {
          const subserviceItems = servicesQuery.data.items.filter(
            (item) => item.service_id === subservice.id,
          )
          const element = (
            <span key={subservice.id} style={{ margin: '0 5px' }}>
              {subservice.key === 1
                ? t('marketplace.models', {
                    count: subserviceItems.length,
                  })
                : t('marketplace.parts', {
                    count: subserviceItems.length,
                  })}{' '}
              {subservice.key === 1 && t('marketplace.generated')}
              {subservice.key === 2 && t('marketplace.textured')}
              {subservice.key === 3 && t('marketplace.branded')}
            </span>
          )

          const isLastElement = index === array.length - 1
          // Avoid adding a slash after the last element
          return isLastElement
            ? [element]
            : [
                element,
                <span
                  key={`slash-${subservice.id}`}
                  style={{ margin: '0 5px' }}
                >
                  /
                </span>,
              ]
        },
      )
      return kpiData

    case '63dcfb10f81b69c6d3a7c42f':
      if (orderedServicesQuery.isLoading) return <span>{t('Loading...')}</span>
      if (orderedServicesQuery.isError)
        return <span>{t('Error occurred')}</span>
      const filteredItems = orderedServicesQuery.data
      const allOrderParts = orderedServicesQuery?.data?.reduce(
        (accumulator, item) => {
          return accumulator.concat(item.list_of_orderparts)
        },
        [],
      )
      return `${t('marketplace.unique_items', {
        count: allOrderParts.length,
      })} / ${t('marketplace.total_ordered', {
        count: filteredItems.length,
      })}`

    default:
      return <div>{t('no_data')}</div>
  }
}

export default useServiceKPI
