import { Divider, Select, Button } from 'antd'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { fetchAllBookedServicesQueries } from '$api/evoAPIs'
import Tooltip from '$components/Tooltip'
import OrderedServicesList from './OrderedServicesList'

const OrderedServiceResult = ({ record }) => {
  const { t } = useTranslation()
  const pageSize = 10
  const [pageNr, setPageNr] = useState(1)
  const [sortOrder, setSortOrder] = useState(1)
  const [sortBy, setSortBy] = useState('time_stamp')

  const {
    data: { items = [], total: totalParts = 0 } = {},
    isLoading: loading,
  } = useQuery({
    queryKey: [
      'bookedServices',
      {
        pageNr,
        pageSize,
        sorting: { key: sortBy, order: sortOrder },
        clientPartId: record.id,
      },
    ],
    queryFn: fetchAllBookedServicesQueries,
    retry: 0,
  })

  const sortOptions = [
    {
      value: 'service_name',
      label: t('bookedservices.service_name'),
    },
    {
      value: 'time_stamp',
      label: t('bookedservices.date_of_request'),
    },
    {
      value: 'status_info',
      label: t('Status'),
    },
  ]

  const hasBookedServices = items && items.length > 0

  return (
    <div style={{ padding: 15 }}>
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3>
          {t('marketplace.booked_services_on')} "{record.name}"
        </h3>
        {hasBookedServices ? (
          <span>
            {t('marketplace.sort_by')}:
            <Divider type="vertical" />
            <Select
              size="small"
              defaultValue={sortBy}
              style={{
                width: 118,
              }}
              onChange={setSortBy}
              options={sortOptions}
            />
            <Divider type="vertical" />
            <Tooltip title={sortOrder === 1 ? t('Ascending') : t('Descending')}>
              <Button
                size="small"
                shape="circle"
                icon={sortOrder === 1 ? <UpOutlined /> : <DownOutlined />}
                onClick={() => setSortOrder((order) => -order)}
              />
            </Tooltip>
          </span>
        ) : (
          ''
        )}
      </div>
      <OrderedServicesList
        data={items}
        pageSize={pageSize}
        setPageNr={setPageNr}
        totalParts={totalParts}
        pageNr={pageNr}
        loading={loading}
      />
    </div>
  )
}

export default OrderedServiceResult
