import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { App, Button, Popconfirm } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { evoConsolidation } from '$api'
import { putStatusAttributes } from '$api/client'
import {
  fetchAllServices,
  postOrder,
  postOrderList,
  postPartsConsolidation,
} from '$api/evoAPIs'
import StatusPage from '../Defaultwizard/StatusPage'
import StepThreeSummaryOrderPart from './StepThreeSummaryOrderPart'
import {
  StyledStepButtons,
  StyledSteps,
} from '../Defaultwizard/styledComponents'
//import StepMaterial from './Step-Material'
import StepOneSelection from '../Defaultwizard/StepOneSelection'
//import StepTwoOrderConfig from './StepTwoOrderConfig'
import StepTwoConfigOrderPart from './StepTwoConfigOrderPart'

const Orderparts = (props) => {
  const { t } = useTranslation()
  const [fcData] = useState({
    not_feasible_parts: [],
    feasible_parts: [],
  })
  const [bookableParts, setBookableParts] = useState()
  const [steps, setSteps] = useState([
    {
      title: t('service.selection'),
      content: '',
    },
    // {
    //   title: t('Configure Material'),
    //   content: '',
    // },
    {
      title: t('service.order_part.step_config_title'),
      content: '',
    },
    {
      title: t('service.order_part.step_place_order'),
      content: '',
    },
  ])
  const [current, setCurrent] = useState(0)
  const { message } = App.useApp()
  const [visible, setVisible] = useState(true)
  const [loading, setLoading] = useState(false)
  const [buttonActive, setButtonActive] = useState(true)
  const [configState, setConfigState] = useState(true)
  const [accumulatedFormData, setAccumulatedFormData] = useState([])
  const [orderPartsListData, setOrderPartsListData] = useState([])
  const queryClient = useQueryClient()
  const { data: servicesData } = useQuery(['services'], fetchAllServices)

  const addBookedOrders = useMutation(
    (formData) => {
      const { amount, material_id, part, stl_file } = formData

      return evoConsolidation
        .post(`${postOrder}`, {
          amount,
          material_id,
          part,
          partlist_name: props.activeGroupName || '',
        })
        .then((data) => {
          // update Part status attribute
          updateClientPartStatus.mutate({
            partsToUpdate: data.data,
            serviceName: 'Request AM Offer',
          })
          // add client part stl file
          uploadSTLFile.mutate({
            db_id_client: part.db_id_client,
            stl_file: stl_file,
            id: data.data.id,
          })
          return data.data
        })
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not book service`)
      },
    },
  )

  const uploadSTLFile = useMutation(
    ({ db_id_client, stl_file, id }) => {
      const formData = new FormData()
      const file = new File([stl_file], 'dateiname.stl', {
        type: 'application/vnd.ms-pki.stl',
      })
      formData.append('file', file)
      return evoConsolidation
        .post(`${postPartsConsolidation}${db_id_client}/file`, formData)
        .then((stl_location) => {
          putSTLLocationInOrderParts.mutate({
            id: id,
            stl_name: stl_location.data,
          })
        })
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not upload STL File`)
      },
    },
  )

  const updateClientPartStatus = useMutation(putStatusAttributes, {
    onSuccess: () => {
      queryClient.invalidateQueries(['allgroups'])
      queryClient.invalidateQueries(['notifications'])
      setLoading(false)
    },
    onError: (err) => {
      message.error(`${err.response.status} Could not update status`)
    },
  })

  const createOrderPartsList = useMutation(
    (body) => {
      return evoConsolidation.post(`${postOrderList}`, body, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
    },
    {
      onSuccess: () => {
        setLoading(false)
      },
      onError: (err) => {
        message.error(`${err.response.status} Could not create order`)
      },
    },
  )

  const putSTLLocationInOrderParts = useMutation(
    ({ id, stl_name }) => {
      return evoConsolidation.put(
        `${postOrder}${id}/file?file_path=${stl_name}`,
      )
    },
    {
      onError: (err) => {
        setLoading(false)
        message.error(`${err.response.status} Could not put STL file location`)
      },
    },
  )

  // Functions

  const updateSteps = useCallback(() => {
    if (bookableParts || servicesData) {
      const newSteps = [
        {
          title: t('service.selection'),
          content: (
            <StepOneSelection
              fcData={fcData}
              selectedParts={props.selectedParts}
              setBookableParts={setBookableParts}
              bookableParts={bookableParts}
              servicesData={servicesData}
              bookedServiceStatus={props.bookedServiceStatus}
            />
          ),
        },
        // {
        //   title: t('Configure Material'),
        //   content: (
        //     <StepMaterial
        //       fcData={fcData}
        //       selectedParts={props.selectedParts}
        //       bookableParts={bookableParts}
        //       setCurrentFormValues={setCurrentFormValues}
        //       setConfigState={setConfigState}
        //       configState={configState}
        //     />
        //   ),
        // },
        {
          title: t('service.order_part.step_config_title'),
          content: (
            <StepTwoConfigOrderPart
              fcData={fcData}
              selectedParts={props.selectedParts}
              bookableParts={bookableParts}
              setAccumulatedFormData={setAccumulatedFormData}
              setOrderPartsListData={setOrderPartsListData}
              setConfigState={setConfigState}
              configState={configState}
              orderToRepeat={props.orderToRepeat}
            />
          ),
        },
        {
          title: t('service.order_part.step_place_order'),
          content: (
            <StepThreeSummaryOrderPart
              fcData={fcData}
              selectedParts={props.selectedParts}
              accumulatedFormData={accumulatedFormData}
              orderPartsListData={orderPartsListData}
              bookableParts={bookableParts}
              setButtonActive={setButtonActive}
              bookedServiceStatus={props.bookedServiceStatus}
            />
          ),
        },
      ]
      setSteps(newSteps)
    }
  }, [
    accumulatedFormData,
    orderPartsListData,
    bookableParts,
    servicesData,
    fcData,
    props.selectedParts,
    setBookableParts,
    setConfigState,
    configState,
    setButtonActive,
    props.bookedServiceStatus,
    props.orderToRepeat,
    t,
  ])

  const handleOk = async () => {
    setCurrent(0)
    props.setSelectedParts([])
    props.setOrderToRepeat(null)
    setButtonActive(true)

    // post to endpoint
    const mutationPromises = accumulatedFormData.map((formData) =>
      addBookedOrders.mutateAsync(formData),
    )
    setLoading(true)
    setVisible(false)

    // 1. POST OrderParts
    let results
    try {
      // wait for all addBookedOrders to be fulfilled
      results = await Promise.all(mutationPromises)
    } catch (error) {
      console.error('Error on one or more booked Orders:', error)
      message.error('Could not create order!')
      return
    }

    // 2. Parse response and create OrderPartsList request body
    let requestBody
    try {
      // prepare orderID's array
      const orderPartIDs = results.map((data) => data.id)
      orderPartsListData.list_of_orderparts = orderPartIDs

      // helper function to match newly created OrderParts to part quantities collected in form
      const exchangePartIdsForOrderPartIds = (obj) =>
        Object.fromEntries(
          Object.entries(obj).map(([partId, orderPartQuantity]) => [
            results.find((orderPart) => orderPart.part.db_id_client === partId)
              .id,
            orderPartQuantity,
          ]),
        )

      requestBody = {
        ...orderPartsListData,
        main_order: {
          ...orderPartsListData.main_order,
          orderpart_quantity: exchangePartIdsForOrderPartIds(
            orderPartsListData.main_order.orderpart_quantity,
          ),
        },
        list_of_suborders: orderPartsListData.list_of_suborders?.map(
          (subOrder) => ({
            ...subOrder,
            orderpart_quantity: exchangePartIdsForOrderPartIds(
              subOrder.orderpart_quantity,
            ),
          }),
        ),
      }
    } catch (error) {
      console.error(
        'Error while matching newly created OrderParts to desired part quantities:',
        error,
      )
      message.error('Could not create order!')
      return // abort before sending request
    }

    // 3. Send request to attach OrderParts to a new OrderPartsList
    createOrderPartsList.mutate(requestBody)
  }

  const next = () => {
    if (current === 0) {
      setConfigState(false)
    }
    setCurrent(current + 1)
  }
  const prev = () => {
    if (current === 1) {
      setConfigState(true)
    }
    setCurrent(current - 1)
  }
  const items = steps
    .filter(function (x) {
      return x !== null
    })
    .map((item) => ({
      key: item.title,
      title: item.title,
    }))

  useEffect(() => {
    updateSteps()
  }, [updateSteps])

  useEffect(() => {
    setConfigState(!(bookableParts && bookableParts.bookableParts.length === 0))
  }, [bookableParts])

  // for prevent infinite loop #eslint
  // useEffect(() => {
  //   fileNamesRef.current = fileNames
  // }, [fileNames])

  // useEffect(() => {
  //   if (fileNames.length > 0 || stlFileName) {
  //     if (fileNamesRef.current !== false) {
  //       const nameArr = [currentOrderId, fileNames, stlFileName]
  //       updateFileLocations.mutate(nameArr)
  //       // this is for preventing infinite loop
  //       fileNamesRef.current = false
  //     }
  //   }
  // }, [fileNames, stlFileName, currentOrderId, updateFileLocations])

  return (
    <>
      {visible && (
        <>
          <h1 style={{ margin: '50px 0' }}>
            {t(props.bookedServiceStatus[1].servicename)}
          </h1>
          <div className="contentcard">
            <StyledSteps current={current} items={items} type="navigation" />
            <div style={{ margin: '50px 0px' }}>{steps[current].content}</div>
            <StyledStepButtons>
              {current < steps.length - 1 && (
                <Popconfirm
                  disabled={configState}
                  placement="topRight"
                  description={
                    <>
                      Some parts need configuration before proceeding.
                      <br />
                      Check the "status" of your part.
                    </>
                  }
                  title={<h4>Some changes have to be made</h4>}
                >
                  <Button
                    type="primary"
                    onClick={() => next()}
                    disabled={!configState}
                  >
                    {t('button.next')}
                  </Button>
                </Popconfirm>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  onClick={() => handleOk()}
                  disabled={!buttonActive}
                >
                  {t('service.order_part.order')}
                </Button>
              )}
              {current > 0 && (
                <Button
                  style={{
                    margin: '0 8px',
                  }}
                  onClick={() => prev()}
                >
                  {t('button.back')}
                </Button>
              )}
            </StyledStepButtons>
          </div>
        </>
      )}
      {!visible && (
        <>
          <StatusPage
            setIsBookModalOpen={props.setIsBookModalOpen}
            loading={loading}
            setCurrent={props.setCurrent}
          />
        </>
      )}
    </>
  )
}

export default Orderparts
