import React, { useState, useEffect } from 'react'
import { Tree } from 'antd'
import type { DataNode, TreeProps } from 'antd/es/tree'
import {
  StyledTree,
  StyledTreeNode,
  StyledTreeSubNode,
} from './styledComponents'

const treeData: DataNode[] = [
  {
    title: (
      <StyledTreeNode>
        <span>Plastik</span>
        <span>3</span>
      </StyledTreeNode>
    ),
    key: 'Plastik',
    children: [
      {
        title: (
          <StyledTreeSubNode>
            <span>PA12</span>
            <span></span>
          </StyledTreeSubNode>
        ),
        key: 'PA12',
      },
      {
        title: (
          <StyledTreeSubNode>
            <span>PA6</span>
            <span></span>
          </StyledTreeSubNode>
        ),
        key: 'PA6',
      },
      {
        title: (
          <StyledTreeSubNode>
            <span>ABS</span>
            <span></span>
          </StyledTreeSubNode>
        ),
        key: 'ABS',
      },
    ],
  },
  {
    title: (
      <StyledTreeNode>
        <span>Metall</span>
        <span>3</span>
      </StyledTreeNode>
    ),
    key: 'Metall',
    children: [
      {
        title: (
          <StyledTreeSubNode>
            <span>PLA Stahl</span>
            <span></span>
          </StyledTreeSubNode>
        ),
        key: 'PLA Stahl',
      },
      {
        title: (
          <StyledTreeSubNode>
            <span>Kupfer</span>
            <span></span>
          </StyledTreeSubNode>
        ),
        key: 'Kupfer',
      },
      {
        title: (
          <StyledTreeSubNode>
            <span>Titan</span>
            <span></span>
          </StyledTreeSubNode>
        ),
        key: 'Titan',
      },
    ],
  },
  {
    title: (
      <StyledTreeNode>
        <span>Kork</span>
        <span>1</span>
      </StyledTreeNode>
    ),
    key: 'Kork',
  },
]

const DWHMaterials2 = (props: any) => {
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>(['PA12', 'PA6'])
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(['PA12'])
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true)

  const onExpand = (expandedKeysValue: React.Key[]) => {
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    setExpandedKeys(expandedKeysValue)
    setAutoExpandParent(false)
  }

  const onCheck: TreeProps['onCheck'] = (checkedKeys, info) => {
    setCheckedKeys(checkedKeys as React.Key[])
  }

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setSelectedKeys(selectedKeysValue)
    props.setSelectedDWHMaterialKeys(selectedKeysValue)
  }

  useEffect(() => {
    if (props.selectedRowKeys.length != 0) {
      setCheckedKeys([])
    }
  }, [props.selectedRowKeys])

  return (
    <StyledTree
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={autoExpandParent}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      treeData={treeData}
    />
  )
}

export default DWHMaterials2
