import styled from 'styled-components'
import { Table, Button } from 'antd'
import { hexToRGBA } from '$utils'

export const StyledTable = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
    background-color: ${(props) => props.theme.colorBgLightOne};
  }

  tbody tr {
    background-color: white;
    //cursor: pointer;
  }

  th::before {
    display: none;
  }

  .feas-col {
    width: 80px;
  }
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #f8f8f8;
  }
  .ant-table-thead .ant-table-row-expand-icon-cell {
    min-width: 60px;
    background: ${(props) => props.theme.colorPrimaryBgHover};
  }
  .ant-table-wrapper {
    margin: 0 15px;
  }
  .ant-table-row-selected {
    background: ${(props) => props.theme.colorPrimaryBg};
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px;
  }
  .ant-table-footer {
    width: 100%;
  }
`

const PartNo = (props) => `
  padding: 5px 15px !important;
  font-size: 12px;
  background: ${props.theme.colorBgLightSeven};
  border-color: ${props.theme.colorBgLightSix} !important;
  margin: -7px 0;
  :hover {
    border-color: ${props.theme.colorBgLightSix};
    background: #e7e7e7;
  }
`

export const PartNoButton = styled(Button)`
  ${(props) => PartNo(props)}
  height: 32px !important;
  :focus {
    background: ${(props) => hexToRGBA(props.theme.colorPrimary, 0.05)};
  }
`

export const PartNoDiv = styled.div`
  ${(props) => PartNo(props)}
  display: inline-block;
  border-radius: 6px;
`
