import PropTypes from 'prop-types'
import { useContext, useState, useEffect } from 'react'
import { Tag, Col, Row, Avatar } from 'antd'
import {
  UserOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  BarChartOutlined,
} from '@ant-design/icons'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { fetchUsersOfCompany } from '../../../api/evoAPIs'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import AuthContextProvider, {
  AuthContext,
} from '../../../context/AuthContextProvider'
import companycard from '../../../assets/img/companycard.png'
import users from '../../../assets/img/users.png'

const Companycard = (props) => {
  const authContext = useContext(AuthContext)
  const [userCount, setUserCount] = useState()

  const { data: userOfCompany } = useQuery(
    ['userofcompany', props.userCompany[0].id],
    fetchUsersOfCompany,
    {
      enabled: !!props.userCompany[0],
      onSuccess: (response) => {
        setUserCount(response.length)
      },
      onError: (e) => {
        console.error(e)
      },
    },
  )

  return (
    <>
      <div style={{ backgroundColor: '#D0DFE3', borderRadius: '8px 8px 0 0' }}>
        <Row>
          <Col span={18}>
            <div style={{ padding: '24px' }}>
              <h3 className="text-primary">
                We are <i>{props.userCompany[0].name}</i> !
              </h3>
              <p>
                a <i>{props.userCompany[1] === false ? 'sub' : 'main'}</i>{' '}
                company at Digital Warehouse
              </p>
            </div>
          </Col>
          <Col span={6}>
            <img
              src={companycard}
              alt=""
              style={{
                width: '130px',
                margin: '16px 0px -5px -42px',
                opacity: '0.8',
              }}
            />
          </Col>
        </Row>
      </div>
      <div style={{ padding: '0 24px 24px' }}>
        <Row justify="space-between">
          <Col span={12}>
            <div style={{ marginTop: '-34px' }}>
              <Avatar
                icon={<BarChartOutlined />}
                style={{
                  margin: '3px 3px 5px',
                  border: '5px solid white',
                  width: '60px',
                  height: '60px',
                  fontSize: '27px',
                  lineHeight: '47px',
                }}
              />
            </div>
            <h4 className="font-size-15 text-truncate">
              {props.userCompany[0].name}
            </h4>
            <Tag>{props.userCompany[1] === false ? 'SUB' : 'MAIN'}</Tag>
          </Col>

          <Col span={12}>
            <div style={{ marginTop: '18px' }}>
              <Row justify="space-between">
                <Col span={12}>
                  <h2 style={{ marginBottom: '7px', marginTop: '10px' }}>
                    {userCount}
                  </h2>
                  <p>Users</p>
                </Col>
                <Col span={12}>
                  <h2 style={{ marginBottom: '7px', marginTop: '10px' }}>
                    {props.subCompanyCount}
                  </h2>
                  {props.userCompany[1] === false ? (
                    <></>
                  ) : (
                    <p className="text-muted mb-0">Sub Companies</p>
                  )}
                </Col>
              </Row>
              <div style={{ textAlign: 'right', marginRight: '17px' }}>
                <Link to="/user" className="btn btn-primary  btn-sm">
                  View users <i className="mdi mdi-arrow-right ms-1" />
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Companycard
