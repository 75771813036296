import {
  OFFER_ACCEPTED,
  OrderPartsListStatusTag,
  REQUEST_RECEIVED,
} from '$components/OrderPartsListStatus'
import ExpirationDate from '$components/OrderPartsListTable/ExpirationDate'
import {
  calculateOrderPartPrice,
  summarizeDeliveryTimes,
} from '$components/OrderPartsListTable/utils'
import { currencyRenderer } from '$utils/i18n'
import { ValidationWrapper } from '$globalStyledComponents/Text'

const createColumns = (
  t,
  materialsById,
  formValues,
  determineStatus,
  conditionalColumnsTitle,
) => {
  const columns = [
    {
      title: conditionalColumnsTitle(
        t('received_requests.request_no'),
        t('received_requests.order_nr'),
        t('received_requests.request_no_order_nr'),
      ),
      dataIndex: 'order_number',
      render: (title) => (
        <div style={{ minWidth: '100px' }}>
          <b>{title}</b>
        </div>
      ),
    },
    {
      title: conditionalColumnsTitle(
        t('received_requests.request_date'),
        t('received_requests.order_date'),
        t('received_requests.request_date_order_date'),
      ),
      render: (record) =>
        t('localized_datetime', {
          val: new Date(record.list_of_orderparts[0].time_stamp),
          formatParams: {
            val: {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },
          },
        }),
    },
    {
      title: t('received_requests.date_of_expiration'),
      dataIndex: 'date_of_expiration',
      render: (date_of_expiration) => (
        <ExpirationDate date_of_expiration={date_of_expiration} />
      ),
    },
    {
      title: t('received_requests.parts_amount'),
      render: (record) => {
        // sum all part quantities in the amount field(s) of list_of_orderparts
        return (
          determineStatus(record) === OFFER_ACCEPTED
            ? record.list_of_orderparts.filter(
                (orderPart) => !orderPart.rejected_by_client,
              )
            : record.list_of_orderparts
        ).reduce((accumulator, { amount }) => accumulator + amount, 0)
      },
    },
    {
      title: t('received_requests.materials'),
      render: (record) => {
        // merges all material names of all list_of_orderparts into one comma-separated string
        const namesString = Array.from(
          new Set(
            (determineStatus(record) === OFFER_ACCEPTED
              ? record.list_of_orderparts.filter(
                  (orderPart) => !orderPart.rejected_by_client,
                )
              : record.list_of_orderparts
            ).map((part) => materialsById[part.material]?.name),
          ),
        )
          .filter((name) => name)
          .join(', ')

        return namesString
      },
    },
    {
      title: t('received_requests.delivery_time'),
      render: (record) => {
        let delivery_times = []

        switch (determineStatus(record)) {
          case REQUEST_RECEIVED:
            delivery_times = record.list_of_orderparts
              .map((orderPart) =>
                orderPart.provider_offers
                  .map((storedOffer) => {
                    const liveOffer =
                      formValues?.[record.id]?.[orderPart.id]?.provider_offers[
                        storedOffer.offer_id
                      ]
                    const offer = liveOffer || storedOffer
                    return [
                      offer.base_production_time,
                      ...(offer.production_time_rules
                        ? Object.values(offer.production_time_rules).map(
                            (rule) => rule.max_days,
                          )
                        : []),
                    ]
                  })
                  .flat(),
              )
              .flat()
            break
          case OFFER_ACCEPTED:
            delivery_times = record.list_of_orderparts
              .filter((orderPart) => !orderPart.rejected_by_client)
              .map((orderPart) =>
                orderPart.provider_offers.map((offer) => offer.delivery_days),
              )
              .flat()
            break
          default:
            delivery_times = record.list_of_orderparts
              .filter((orderPart) => orderPart.provider_offers.length > 0)
              .map((orderPart) =>
                orderPart.provider_offers
                  .map((offer) => [
                    offer.base_production_time,
                    ...(offer.production_time_rules
                      ? offer.production_time_rules.map((rule) => rule.max_days)
                      : []),
                  ])
                  .flat(),
              )
              .flat()
            break
        }

        if (delivery_times.length > 0) {
          return summarizeDeliveryTimes(delivery_times)
        } else {
          return t('received_requests.not_yet_defined')
        }
      },
    },
    {
      title: t('received_requests.price_total'),
      render: (record) => {
        let prices = []
        const status = determineStatus(record)

        let orderParts = record.list_of_orderparts
        if (status === OFFER_ACCEPTED) {
          orderParts = orderParts.filter(
            (orderPart) => !orderPart.rejected_by_client,
          )
        }

        prices = orderParts
          .map((orderPart) => {
            const liveOfferValues = Object.values(
              formValues?.[record.id]?.[orderPart.id]?.provider_offers || {},
            )

            if (liveOfferValues?.length) {
              return calculateOrderPartPrice(
                liveOfferValues,
                record.client_accepted_offer,
              )
            } else if (orderPart.provider_offers?.length) {
              return calculateOrderPartPrice(
                orderPart.provider_offers,
                record.client_accepted_offer,
              )
            }

            return undefined
          })
          .filter((price) => !!price)

        if (prices.length > 0) {
          const price = prices.reduce((sum, a) => sum + a, 0)
          return (
            <ValidationWrapper valid={price > 0}>
              {currencyRenderer(t)(price)}
            </ValidationWrapper>
          )
        } else {
          return t('received_requests.not_yet_defined')
        }
      },
    },
    {
      title: t('received_requests.status'),
      render: (record) => <OrderPartsListStatusTag orderPartsList={record} />,
    },
  ]

  return columns
}

export default createColumns
