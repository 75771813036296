import { StyledLayout } from '$globalStyledComponents/Layout'
import Footer from '$components/Footer'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const { Content } = StyledLayout

const Imprint = () => {
  const { t } = useTranslation()

  return (
    <StyledLayout className="layout">
      <Content>
        <div className="header-with-inlines">
          <h1>{t('imprint')}</h1>
          <Link to="/data-privacy" className="jump">
            <InfoCircleOutlined
              style={{ fontSize: '13px', margin: '4px 8px 0 2px' }}
            />
            {t('Data protection')}
          </Link>
          <Link to="/legal-information" className="jump">
            <InfoCircleOutlined
              style={{ fontSize: '13px', margin: '4px 8px 0 2px' }}
            />
            {t('Legal notices')}
          </Link>
        </div>
        <div className="myevocard">
          <div style={{ margin: '0 200px' }}>
            <ImprintText />
          </div>
        </div>
      </Content>
      <Footer />
    </StyledLayout>
  )
}

export default Imprint

export const ImprintText = () => {
  const { i18n } = useTranslation()
  if (i18n.language === 'de') {
    return (
      <>
        <h2>Anbieter</h2>
        <p>
          Daimler Buses GmbH
          <br />
          Fasanenweg 10
          <br />
          70771 Leinfelden-Echterdingen
          <br />
          Deutschland
          <br />
          Tel.: +49 731-181-0
          <br />
          E-Mail:{' '}
          <a href="mailto:info.daimlerbuses@daimlertruck.com">
            info.daimlerbuses@daimlertruck.com
          </a>
        </p>
        <p>
          <b>Vertreten durch die Geschäftsführung:</b> Till Oberwörder
          (Vorsitzender/CEO), Michael Klein, Peter Rödder, Mirko Sgodda, Gustav
          Tuschen, Lutz Wittig
        </p>
        <p>
          Handelsregister beim Amtsgericht Stuttgart, Nr. HRB 17316
          <br />
          Umsatzsteueridentifikationsnummer: DE 147 032 272
        </p>
      </>
    )
  }

  return (
    <>
      <h2>Provider</h2>
      <p>
        Daimler Buses GmbH
        <br />
        Fasanenweg 10
        <br />
        70771 Leinfelden-Echterdingen
        <br />
        Germany
        <br />
        Phone: +49 731-181-0
        <br />
        E-Mail:{' '}
        <a href="mailto:info.daimlerbuses@daimlertruck.com">
          info.daimlerbuses@daimlertruck.com
        </a>
      </p>
      <p>
        <b>Represented by the management:</b> Till Oberwörder
        (Vorsitzender/CEO), Michael Klein, Peter Rödder, Mirko Sgodda, Gustav
        Tuschen, Lutz Wittig
      </p>
      <p>
        Commercial register at Amtsgericht Stuttgart, Nr. HRB 17316
        <br />
        Sales tax identification number: DE 147 032 272
      </p>
    </>
  )
}
